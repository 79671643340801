import { ref, onMounted } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { createToaster } from '@meforma/vue-toaster';

/* Example column:
 { 
    key: 'workerName',
    header: 'Brygadzista',
    computed: true, // only for DictionaryTable (for now), add only if true
    visible: true,
    requiredKeysOverride: ['worker'], // optional, if the object has no 'workerName' key, but has 'worker' key, can be used to require more than one key
    sortable: true, // not working for DictionaryTable yet
    sortKey: sortingOptions.EMAIL,  // only if sortable is true
    thStyle: 'width: 200px;',
    tdStyle: 'width: 200px;',
  }
*/

export function useTableColumns(tableId, initialColumns, data = []) {
  const authStore = useAuthStore();
  const toaster = createToaster({ position: 'top-right', duration: 3000 });
  const isSettingsSaving = ref(false);
  const tableData = ref(data);
  const allColumns = ref(initialColumns);
  const accessibleColumns = ref(getAccessibleColumns());
  const visibleColumns = ref([...accessibleColumns.value]);

  // Columns dragging
  const draggedColumn = ref(null);
  const dragOverColumn = ref(null);

  function initialize() {
    allColumns.value.forEach((col) => {
      if (col.visible == undefined) col.visible = true;
    });
  }

  function getNestedValue(obj, path) {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

  function hasColumnData(column) {
    if (!tableData.value?.length) return true; // Show all if no data

    return tableData.value.some((item) => {
      const requiredKeys =
        column.requiredKeysOverride != undefined ? column.requiredKeysOverride : [column.key];

      for (const key of requiredKeys) {
        const value = key.includes('.') ? getNestedValue(item, key) : item[key];

        if (value === undefined) return false;
      }

      return true;
    });
  }

  const handleColumnVisibilityChange = (mode) => {
    if (mode === 'all') {
      allColumns.value.forEach((col) => (col.visible = true));
    }

    visibleColumns.value = accessibleColumns.value.filter((col) => col.visible);
  };

  function updateTableData(newData) {
    tableData.value = newData;
    accessibleColumns.value = getAccessibleColumns();
    handleColumnVisibilityChange();
  }

  const saveColumnsSettings = async () => {
    if (isSettingsSaving.value) return;

    isSettingsSaving.value = true;

    try {
      const currentSettings = authStore.loggedInUserSettings?.tables || {};
      const settings = {
        tables: {
          ...currentSettings,
          [tableId]: {
            hidden_columns: allColumns.value.filter((col) => !col.visible).map((col) => col.key),
            column_order: visibleColumns.value.map((col) => col.key)
          }
        }
      };

      await authStore.updateUserSettings(JSON.stringify(settings));
      toaster.show('Ustawienia zostały zapisane', { type: 'success' });
    } catch (error) {
      toaster.show('Nie udało się zapisać ustawień', { type: 'error' });
    } finally {
      isSettingsSaving.value = false;
    }
  };

  function canAccessColumn(column) {
    return column.accessKey == null || authStore.canAccess(column.accessKey);
  }

  function getAccessibleColumns() {
    return allColumns.value.filter((column) => canAccessColumn(column) && hasColumnData(column));
  }

  function getColumnThStyle(column) {
    const sortableStyleSection = column.sortable ? 'cursor: pointer;' : '';
    const customStyleSection = column.thStyle ?? '';

    return `${sortableStyleSection} ${customStyleSection}`;
  }

  function getColumnTdStyle(column) {
    return column.tdStyle ?? '';
  }

  const loadColumnsSettings = () => {
    const userSettings = authStore.loggedInUserSettings;

    if (!userSettings?.tables?.[tableId]) return;

    if (userSettings.tables[tableId].hidden_columns) {
      const hiddenColumns = userSettings.tables[tableId].hidden_columns;
      allColumns.value.forEach((col) => {
        col.visible = !hiddenColumns.includes(col.key);
      });
    }

    if (userSettings.tables[tableId].column_order) {
      const order = userSettings.tables[tableId].column_order;
      const orderedColumns = [];

      order.forEach((key) => {
        const column = allColumns.value.find((col) => col.key === key);
        if (column) orderedColumns.push(column);
      });

      allColumns.value.forEach((column) => {
        if (!order.includes(column.key)) {
          orderedColumns.push(column);
        }
      });

      allColumns.value = orderedColumns;
    }

    handleColumnVisibilityChange();
  };

  function handleDragStart(column) {
    draggedColumn.value = column;
  }

  function handleDragOver(e, column) {
    e.preventDefault();
    dragOverColumn.value = column;
  }

  function haveColumnsOrderChanged(oldColumns, newColumns) {
    if (oldColumns.length !== newColumns.length) return true;

    return oldColumns.some((col, index) => col.key !== newColumns[index].key);
  }

  async function handleColumnDrop(e) {
    e.preventDefault();

    if (!draggedColumn.value || !dragOverColumn.value) return;

    const oldIndex = visibleColumns.value.indexOf(draggedColumn.value);
    const newIndex = visibleColumns.value.indexOf(dragOverColumn.value);
    const oldColumns = [...visibleColumns.value];

    const columns = [...visibleColumns.value];
    columns.splice(oldIndex, 1);
    columns.splice(newIndex, 0, draggedColumn.value);

    if (haveColumnsOrderChanged(oldColumns, columns)) {
      visibleColumns.value = columns;
      await saveColumnsSettings();
    }

    draggedColumn.value = null;
    dragOverColumn.value = null;
  }

  function resetColumnsOrder() {
    allColumns.value = [...initialColumns];
    accessibleColumns.value = getAccessibleColumns();
    handleColumnVisibilityChange();
    saveColumnsSettings();
  }

  initialize();

  onMounted(() => {
    loadColumnsSettings();
  });

  return {
    allColumns,
    accessibleColumns,
    visibleColumns,
    isSettingsSaving,
    handleColumnVisibilityChange,
    saveColumnsSettings,
    getNestedValue,
    updateTableData,
    getColumnThStyle,
    getColumnTdStyle,
    draggedColumn,
    dragOverColumn,
    handleDragStart,
    handleDragOver,
    handleColumnDrop,
    resetColumnsOrder
  };
}
