<script setup>
/* eslint-env jquery */
import { ref, computed, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { getDateWithoutTime, addDaysToDate } from '@/helpers/dateFormatHelper.js';
import FormErrorPanel from '@/components/utils/FormErrorPanel.vue';
import ModalTable from '@/components/utils/ModalTable.vue';
import {
  MODAL_ORDER_TYPES_TABLE,
  MODAL_DIRECTIONS_TABLE,
  MODAL_REGIONS_TABLE,
  MODAL_COUNTIES_TABLE,
  MODAL_USERS_TABLE,
  MODAL_BOROUGHS_TABLE,
  MODAL_ORDER_INITIATIVES_TABLE
} from '../../data/constants/modalConstants.js';
import ModalInputField from '@/components/utils/ModalInputField.vue';
import {
  ORDER_TYPES_IDS_WITHOUT_GEODESY,
  ORDER_DIRECTION_SSS_TYPE_ID,
  ORDER_DIRECTION_STANDARD_TYPE_ID,
  ORDER_DEADLINE_DAYS_FOR_DIRECTION_SSS,
  ORDER_DEADLINE_DAYS_FOR_DIRECTION_STANDARD
} from '../../data/constants/appConstants.js';
import { createToaster } from '@meforma/vue-toaster';
import { TOASTER_SERVER_ERROR_MSG } from '../../data/constants/toasterMessagesConstants';

const RepositoryOrders = RepositoryFactory.get('orders');
const toaster = createToaster({ position: 'top-right', duration: 3000 });
const router = useRouter();
const route = useRoute();
const modalTable = ref();
const formErrors = ref([]);
const isLoading = ref(false);
const orderTypeDisplayName = ref(null);
const directionDisplayName = ref(null);
const regionDisplayName = ref(null);
const countyDisplayName = ref(null);
const boroughDisplayName = ref(null);
const coordinatorDisplayName = ref(null);
const initiativeDisplayName = ref(null);
const order = ref({
  id: null,
  id_order_type: null,
  id_direction: null,
  order_number: null,
  ebp: null,
  id_region: null,
  id_powiat: null,
  ig_gmina: null,
  description: null,
  target: null,
  id_coordinator_opl: null,
  receive_date: null,
  deadline: null,
  surveyor_deadline: null,
  split_order_number: [null, null, null, null],
  id_order_initiative: null
});

const addMode = computed(() => {
  return isNaN(parseInt(route.params.id));
});

const idForEdit = computed(() => {
  return parseInt(route.params.id);
});

watch(
  () => order.value?.receive_date,
  () => {
    if (addMode.value) {
      updateDeadlineDate();
    }
  }
);

watch(
  () => order.value?.id_direction,
  () => {
    if (addMode.value) {
      updateDeadlineDate();
    }
  }
);

function updateDeadlineDate() {
  if (order.value?.receive_date == null) return;

  switch (order.value?.id_direction) {
    case ORDER_DIRECTION_SSS_TYPE_ID:
      order.value.deadline = addDaysToDate(
        order.value?.receive_date,
        ORDER_DEADLINE_DAYS_FOR_DIRECTION_SSS
      );
      break;
    case ORDER_DIRECTION_STANDARD_TYPE_ID:
      order.value.deadline = addDaysToDate(
        order.value?.receive_date,
        ORDER_DEADLINE_DAYS_FOR_DIRECTION_STANDARD
      );
      break;
  }

  $('#orderDeadline .input-group.date').datepicker('setDate', order.value.deadline);
}

function submitForm(e) {
  e.preventDefault();

  if (addMode.value) {
    sendAddRequest();
  } else {
    sendEditRequest();
  }
}

async function getOrderDataToEdit() {
  isLoading.value = true;

  let fetchedOrder = await RepositoryOrders.getOrderDataToEdit(idForEdit.value);

  if (fetchedOrder == null) return;

  order.value = {
    id: fetchedOrder.id,
    id_order_type: fetchedOrder.order_type?.id,
    id_direction: fetchedOrder.direction?.id,
    order_number: fetchedOrder.order_number,
    ebp: fetchedOrder.ebp,
    id_region: fetchedOrder.region?.id,
    id_powiat: fetchedOrder.powiat?.id,
    id_gmina: fetchedOrder.gmina?.id,
    description: fetchedOrder.description,
    target: fetchedOrder.target,
    id_coordinator_opl: fetchedOrder.coordinator_opl?.id,
    receive_date: getDateWithoutTime(fetchedOrder.receive_date),
    deadline: getDateWithoutTime(fetchedOrder.deadline),
    surveyor_deadline: getDateWithoutTime(fetchedOrder.surveyor_deadline),
    id_order_initiative: fetchedOrder.order_initiative?.id
  };

  updateSplitOrderNumber();

  $('#orderReceiveDate .input-group.date').datepicker('setDate', order.value.receive_date);
  $('#orderDeadline .input-group.date').datepicker('setDate', order.value.deadline);
  $('#orderSurveyorDeadline .input-group.date').datepicker(
    'setDate',
    order.value.surveyor_deadline
  );

  orderTypeDisplayName.value = fetchedOrder.order_type?.name ?? '-';
  directionDisplayName.value = fetchedOrder.direction?.name ?? '-';
  regionDisplayName.value = fetchedOrder.region?.name ?? '-';
  countyDisplayName.value = fetchedOrder.powiat?.name ?? '-';
  boroughDisplayName.value = fetchedOrder.gmina?.name ?? '-';
  coordinatorDisplayName.value =
    (fetchedOrder.coordinator_opl?.firstName ?? '') +
    ' ' +
    (fetchedOrder.coordinator_opl?.lastName ?? '');
  initiativeDisplayName.value = fetchedOrder.order_initiative?.name ?? '-';

  isLoading.value = false;
}

function updateSplitOrderNumber() {
  order.value.split_order_number =
    order.value.order_number == null
      ? [null, null, null, null]
      : order.value.order_number.split('/');

  if (order.value.split_order_number.length > 4) {
    order.value.split_order_number[3] = order.value.split_order_number.slice(3).join('/');
  }
}

async function sendAddRequest() {
  isLoading.value = true;

  delete order.value.id;

  if (ORDER_TYPES_IDS_WITHOUT_GEODESY.includes(order.value?.id_order_type)) {
    delete order.value.surveyor_deadline;
  }

  order.value.order_number =
    order.value.split_order_number[0] +
    '/' +
    order.value.split_order_number[1] +
    '/' +
    order.value.split_order_number[2] +
    '/' +
    order.value.split_order_number[3];

  order.value.target = 0;

  let response = await RepositoryOrders.createOrder(order.value);

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { type: 'error' });
  } else {
    if (response) {
      if (response.id != null) {
        toaster.show('Zlecenie zostało utworzone.', { type: 'success' });
        router.push({ name: 'orderAddEdit', params: { id: response.id } });
      } else formErrors.value = response;
    }
  }

  isLoading.value = false;
}

async function sendEditRequest() {
  isLoading.value = true;

  if (ORDER_TYPES_IDS_WITHOUT_GEODESY.includes(order.value?.id_order_type)) {
    delete order.value.surveyor_deadline;
  }

  order.value.order_number =
    order.value.split_order_number[0] +
    '/' +
    order.value.split_order_number[1] +
    '/' +
    order.value.split_order_number[2] +
    '/' +
    order.value.split_order_number[3];

  order.value.target = 0;

  let response = await RepositoryOrders.updateOrder(order.value);

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { type: 'error' });
  } else {
    if (response?.message) formErrors.value = response.message;
    else {
      toaster.show('Zlecenie zostało zaktualizowane.', { type: 'success' });
      showDetails(response?.id);
    }
  }

  isLoading.value = false;
}

function onModalCompleted(data) {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_ORDER_TYPES_TABLE:
      order.value.id_order_type = data?.id ?? null;
      orderTypeDisplayName.value = data?.name ?? '';
      break;
    case MODAL_DIRECTIONS_TABLE:
      order.value.id_direction = data?.id ?? null;
      directionDisplayName.value = data?.name ?? '';
      break;
    case MODAL_REGIONS_TABLE:
      order.value.id_region = data?.id ?? null;
      regionDisplayName.value = data?.name ?? '';
      break;
    case MODAL_COUNTIES_TABLE:
      order.value.id_powiat = data?.id ?? null;
      countyDisplayName.value = data?.name ?? '';
      break;
    case MODAL_BOROUGHS_TABLE:
      order.value.id_gmina = data?.id ?? null;
      boroughDisplayName.value = data?.name ?? '';
      break;
    case MODAL_USERS_TABLE:
      order.value.id_coordinator_opl = data?.id ?? null;
      coordinatorDisplayName.value = (data?.firstName ?? '') + ' ' + (data?.lastName ?? '');
      break;
    case MODAL_ORDER_INITIATIVES_TABLE:
      order.value.id_order_initiative = data?.id ?? null;
      initiativeDisplayName.value = data?.name ?? '';
      break;
  }
}

function onModalRejected() {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_ORDER_TYPES_TABLE:
      order.value.id_order_type = null;
      orderTypeDisplayName.value = null;
      break;
    case MODAL_DIRECTIONS_TABLE:
      order.value.id_direction = null;
      directionDisplayName.value = null;
      break;
    case MODAL_REGIONS_TABLE:
      order.value.id_region = null;
      regionDisplayName.value = null;
      break;
    case MODAL_COUNTIES_TABLE:
      order.value.id_powiat = null;
      countyDisplayName.value = null;
      break;
    case MODAL_BOROUGHS_TABLE:
      order.value.id_gmina = null;
      boroughDisplayName.value = null;
      break;
    case MODAL_USERS_TABLE:
      order.value.id_coordinator_opl = null;
      coordinatorDisplayName.value = null;
      break;
    case MODAL_ORDER_INITIATIVES_TABLE:
      order.value.id_order_initiative = null;
      initiativeDisplayName.value = null;
      break;
  }
}

function showDetails(id) {
  if (id == null) return;
  router.push({ name: 'orderDetails', params: { id } });
}

function updateReceiveDateFromPicker() {
  order.value.receive_date = document.getElementById('receiveDateId').value;
}

function updateDeadlineFromPicker() {
  order.value.deadline = document.getElementById('deadlineId').value;
}

function updateSurveyorDeadlineFromPicker() {
  order.value.surveyor_deadline = document.getElementById('surveyorDeadlineId').value;
}

function setInitialDates() {
  let now = new Date();
  let month = now.getMonth() + 1;
  let monthString = month.toString().length === 1 ? '0' + month : month;

  order.value.receive_date = now.getFullYear() + '-' + monthString + '-' + now.getDate();
  order.value.deadline = now.getFullYear() + '-' + monthString + '-' + now.getDate();
  order.value.surveyor_deadline = now.getFullYear() + '-' + monthString + '-' + now.getDate();
}

// created
setInitialDates();

if (!addMode.value) {
  getOrderDataToEdit();
}

onMounted(() => {
  const datepickerConfig = {
    todayBtn: 'linked',
    keyboardNavigation: true,
    forceParse: true,
    calendarWeeks: true,
    autoclose: true,
    weekStart: 1
  };

  $('#orderReceiveDate .input-group.date').datepicker(datepickerConfig);
  $(document.body).on('change', 'input[id^="receiveDateId"]', updateReceiveDateFromPicker);

  $('#orderDeadline .input-group.date').datepicker(datepickerConfig);
  $(document.body).on('change', 'input[id^="deadlineId"]', updateDeadlineFromPicker);

  $('#orderSurveyorDeadline .input-group.date').datepicker(datepickerConfig);

  $(document.body).on(
    'change',
    'input[id^="surveyorDeadlineId"]',
    updateSurveyorDeadlineFromPicker
  );
});
</script>

<template>
  <div>
    <FormErrorPanel :errors="formErrors" />

    <div id="row-main" class="row">
      <div id="content" class="col-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5 v-if="addMode">Dodaj zlecenie</h5>
            <h5 v-else>Edytuj zlecenie</h5>
          </div>
          <div class="ibox-content" :class="{ 'sk-loading': isLoading }">
            <div class="sk-spinner sk-spinner-three-bounce">
              <div class="sk-bounce1"></div>
              <div class="sk-bounce2"></div>
              <div class="sk-bounce3"></div>
            </div>

            <form role="form" @submit="submitForm">
              <ModalInputField
                :disabled="!addMode"
                title="Typ zlecenia"
                :displayText="orderTypeDisplayName"
                nullText="Wybierz typ zlecenia"
                :required="true"
                @showModal="modalTable.showModal(MODAL_ORDER_TYPES_TABLE)"
              />

              <div class="form-group">
                <label>Numer zlecenia</label>

                <div style="display: flex; flex-wrap: wrap; row-gap: 10px">
                  <input
                    style="width: 100px"
                    type="text"
                    v-model="order.split_order_number[0]"
                    class="form-control"
                    required
                    minlength="1"
                    maxlength="100"
                  />
                  <span class="ml-1 mr-1" style="font-size: 24px">/</span>
                  <input
                    type="text"
                    style="width: 100px"
                    v-model="order.split_order_number[1]"
                    class="form-control"
                    required
                    minlength="1"
                    maxlength="100"
                  />
                  <span class="ml-1 mr-1" style="font-size: 24px">/</span>
                  <input
                    type="text"
                    style="width: 100px"
                    v-model="order.split_order_number[2]"
                    class="form-control"
                    required
                    minlength="1"
                    maxlength="100"
                  />
                  <span class="ml-1 mr-1" style="font-size: 24px">/</span>
                  <input
                    type="text"
                    style="width: 100px"
                    v-model="order.split_order_number[3]"
                    class="form-control"
                    required
                    minlength="1"
                    maxlength="100"
                  />
                </div>
              </div>

              <ModalInputField
                title="Rodzaj zlecenia"
                :displayText="directionDisplayName"
                nullText="Wybierz rodzaj zlecenia"
                :required="true"
                @showModal="modalTable.showModal(MODAL_DIRECTIONS_TABLE)"
              />

              <ModalInputField
                title="Region"
                :displayText="regionDisplayName"
                nullText="Wybierz region"
                :required="true"
                @showModal="modalTable.showModal(MODAL_REGIONS_TABLE)"
              />

              <ModalInputField
                title="Powiat"
                :displayText="countyDisplayName"
                nullText="Wybierz powiat"
                :required="true"
                @showModal="modalTable.showModal(MODAL_COUNTIES_TABLE)"
              />

              <ModalInputField
                title="Gmina"
                :displayText="boroughDisplayName"
                nullText="Wybierz gminę"
                :required="true"
                @showModal="modalTable.showModal(MODAL_BOROUGHS_TABLE)"
              />

              <div class="form-group">
                <label>Opis ogólny</label>

                <textarea
                  type="text"
                  placeholder="Wpisz opis"
                  v-model="order.description"
                  class="form-control"
                  maxlength="500"
                  style="height: 150px"
                />
              </div>

              <ModalInputField
                title="Koordynator"
                :displayText="coordinatorDisplayName"
                nullText="Wybierz koordynatora"
                :required="true"
                @showModal="
                  modalTable.showModal(MODAL_USERS_TABLE, {
                    additionalProps: {
                      filter: { url: 'user/coordinators' }
                    }
                  })
                "
              />

              <div class="form-group" id="orderReceiveDate">
                <label>Data wpływu zlecenia</label>
                <div class="input-group date">
                  <span class="input-group-addon" :style="'background-color: #e9ecef'"
                    ><i class="fa fa-calendar"></i></span
                  ><input
                    id="receiveDateId"
                    type="text"
                    class="form-control"
                    v-model="order.receive_date"
                  />
                </div>
              </div>

              <div class="form-group" id="orderDeadline">
                <label>Termin realizacji</label>
                <div class="input-group date">
                  <span class="input-group-addon" :style="'background-color: #e9ecef'"
                    ><i class="fa fa-calendar"></i></span
                  ><input
                    id="deadlineId"
                    type="text"
                    class="form-control"
                    v-model="order.deadline"
                  />
                </div>
              </div>

              <div
                v-show="!(order && ORDER_TYPES_IDS_WITHOUT_GEODESY.includes(order.id_order_type))"
                class="form-group"
                id="orderSurveyorDeadline"
              >
                <label>Data wymaganego zakończenia dla geodety</label>
                <div class="input-group date">
                  <span class="input-group-addon" :style="'background-color: #e9ecef'"
                    ><i class="fa fa-calendar"></i></span
                  ><input
                    id="surveyorDeadlineId"
                    type="text"
                    class="form-control"
                    v-model="order.surveyor_deadline"
                  />
                </div>
              </div>

              <div class="form-group">
                <label>Numer EBP</label>
                <input
                  type="text"
                  placeholder="Wpisz numer EBP"
                  v-model="order.ebp"
                  class="form-control"
                  minlength="1"
                  maxlength="100"
                />
              </div>

              <ModalInputField
                title="Inicjatywa"
                :displayText="initiativeDisplayName"
                nullText="Wybierz inicjatywę"
                :required="true"
                @showModal="modalTable.showModal(MODAL_ORDER_INITIATIVES_TABLE)"
              />

              <div class="row">
                <div class="col-12">
                  <button class="float-right btn btn-sm btn-primary m-t-n-xs" type="submit">
                    <strong v-if="addMode">Dodaj</strong>
                    <strong v-else>Zapisz</strong>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <ModalTable
      ref="modalTable"
      @onModalCompleted="onModalCompleted"
      @onModalRejected="onModalRejected"
    ></ModalTable>
  </div>
</template>

<style scoped></style>
