<script setup>
import { ref, computed, onMounted } from 'vue';
/* eslint-disable no-undef */
import {
  MODAL_USERS_TABLE,
  MODAL_ORDER_STATUSES_TABLE,
  MODAL_ORDER_TYPES_TABLE,
  MODAL_DIRECTIONS_TABLE,
  MODAL_REGIONS_TABLE,
  MODAL_COUNTIES_TABLE,
  MODAL_ORDER_GEODETIC_PHASES_TABLE
} from '@/data/constants/modalConstants.js';
import ModalInputField from '@/components/utils/ModalInputField.vue';
import ModalTable from '@/components/utils/ModalTable.vue';
import DateRangeInput from '../utils/DateRangeInput.vue';
import { multifilterApplied } from '../../helpers/tablesHelper';
import { isNotEmptyArray } from '../../helpers/utilsHelper';

const COORDINATORS_MODAL_URL = 'user/coordinators';

const props = defineProps({
  longerSearchBar: { type: Boolean, default: false },
  userFilterEnabled: { type: Boolean, default: true },
  hideSearchBar: { type: Boolean, default: false },
  oneFilterPerRow: { type: Boolean, default: false }
});

const emit = defineEmits(['fetchData']);

const modalTable = ref();
const searchBox = ref('');
const filterPanel = ref({
  coordinators: null,
  orderStatuses: null,
  orderTypes: null,
  priorityChange: null,
  directions: null,
  regions: null,
  counties: null,
  geodeticPhases: null,
  userOrderFinishedDate: {
    startDate: null,
    endDate: null
  }
});

const filterPanelDisplay = ref({
  coordinatorsDisplayName: null,
  orderStatusesDisplayName: null,
  orderTypesDisplayName: null,
  directionsDisplayName: null,
  regionsDisplayName: null,
  countiesDisplayName: null,
  orderGeodeticPhasesDisplayName: null
});

const appliedFilter = ref(new Object());

const filtersCount = computed(() => {
  let count = 0;

  for (const property in appliedFilter.value) {
    if (isFilterDateRange(appliedFilter.value[property])) {
      if (
        appliedFilter.value[property].startDate != null ||
        appliedFilter.value[property].endDate != null
      )
        count++;
    } else {
      if (appliedFilter.value[property] != null) count++;
    }
  }

  return count;
});

function isFilterDateRange(filter) {
  return filter?.startDate !== undefined && filter?.endDate !== undefined;
}

const coordinatorsApplied = computed(() => {
  return multifilterApplied(filterPanel.value?.coordinators, appliedFilter.value?.coordinators);
});

const orderStatusesApplied = computed(() => {
  return multifilterApplied(filterPanel.value?.orderStatuses, appliedFilter.value?.orderStatuses);
});

const orderTypesApplied = computed(() => {
  return multifilterApplied(filterPanel.value?.orderTypes, appliedFilter.value?.orderTypes);
});

const priorityChangeApplied = computed(() => {
  return filterApplied(filterPanel.value?.priorityChange, appliedFilter.value?.priorityChange);
});

const directionsApplied = computed(() => {
  return multifilterApplied(filterPanel.value?.directions, appliedFilter.value?.directions);
});

const regionsApplied = computed(() => {
  return multifilterApplied(filterPanel.value?.regions, appliedFilter.value?.regions);
});

const countiesApplied = computed(() => {
  return multifilterApplied(filterPanel.value?.counties, appliedFilter.value?.counties);
});

const orderGeodeticPhasesApplied = computed(() => {
  return multifilterApplied(filterPanel.value?.geodeticPhases, appliedFilter.value?.geodeticPhases);
});

const userOrderFinishedDateApplied = computed(() => {
  return (
    filterApplied(
      filterPanel.value?.userOrderFinishedDate?.startDate,
      appliedFilter.value?.userOrderFinishedDate?.startDate
    ) &&
    filterApplied(
      filterPanel.value?.userOrderFinishedDate?.endDate,
      appliedFilter.value?.userOrderFinishedDate?.endDate
    )
  );
});

onMounted(() => {
  configureCollapsingFilterPanel();
});

function configureCollapsingFilterPanel() {
  $('.collapse-link-modified')
    .off('click')
    .on('click', function (e) {
      e.preventDefault();
      var ibox = $(this).closest('div.ibox');
      var button = $(this).find('i');
      var content = ibox.children('.ibox-content');
      content.slideToggle(200);
      button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
      setTimeout(function () {
        ibox.resize();
        ibox.find('[id^=map-]').resize();
      }, 50);
    });
}

function onModalCompleted(data, data2) {
  switch (modalTable.value.modal.type) {
    case MODAL_USERS_TABLE:
      filterPanel.value.coordinators = isNotEmptyArray(data2) ? data2 : null;
      filterPanelDisplay.value.coordinatorsDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.firstName !== null && obj.lastName !== null)
            .map((obj) => obj.firstName + ' ' + obj.lastName)
            .join(', ')
        : '-';
      break;
    case MODAL_ORDER_STATUSES_TABLE:
      filterPanel.value.orderStatuses = isNotEmptyArray(data2) ? data2 : null;
      filterPanelDisplay.value.orderStatusesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_ORDER_TYPES_TABLE:
      filterPanel.value.orderTypes = isNotEmptyArray(data2) ? data2 : null;
      filterPanelDisplay.value.orderTypesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_DIRECTIONS_TABLE:
      filterPanel.value.directions = isNotEmptyArray(data2) ? data2 : null;
      filterPanelDisplay.value.directionsDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_REGIONS_TABLE:
      filterPanel.value.regions = isNotEmptyArray(data2) ? data2 : null;
      filterPanelDisplay.value.regionsDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_COUNTIES_TABLE:
      filterPanel.value.counties = isNotEmptyArray(data2) ? data2 : null;
      filterPanelDisplay.value.countiesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_ORDER_GEODETIC_PHASES_TABLE:
      filterPanel.value.geodeticPhases = isNotEmptyArray(data2) ? data2 : null;
      filterPanelDisplay.value.orderGeodeticPhasesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
  }
}

function onModalRejected() {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_USERS_TABLE:
      filterPanel.value.coordinators = null;
      filterPanelDisplay.value.coordinatorsDisplayName = null;
      break;
    case MODAL_ORDER_STATUSES_TABLE:
      filterPanel.value.orderStatuses = null;
      filterPanelDisplay.value.orderStatusesDisplayName = null;
      break;
    case MODAL_ORDER_TYPES_TABLE:
      filterPanel.value.orderTypes = null;
      filterPanelDisplay.value.orderTypesDisplayName = null;
      break;
    case MODAL_DIRECTIONS_TABLE:
      filterPanel.value.directions = null;
      filterPanelDisplay.value.directionsDisplayName = null;
      break;
    case MODAL_REGIONS_TABLE:
      filterPanel.value.regions = null;
      filterPanelDisplay.value.regionsDisplayName = null;
      break;
    case MODAL_COUNTIES_TABLE:
      filterPanel.value.counties = null;
      filterPanelDisplay.value.countiesDisplayName = null;
      break;
    case MODAL_ORDER_GEODETIC_PHASES_TABLE:
      filterPanel.value.geodeticPhases = null;
      filterPanelDisplay.value.orderGeodeticPhasesDisplayName = null;
      break;
  }
}

function onClearAllFiltersButtonClicked() {
  for (const property in filterPanel.value) {
    if (isFilterDateRange(filterPanel.value[property])) {
      filterPanel.value[property].startDate = null;
      filterPanel.value[property].endDate = null;
    } else {
      filterPanel.value[property] = null;
    }
  }

  for (const property in filterPanelDisplay.value) {
    filterPanelDisplay.value[property] = null;
  }

  triggerFetchData();
}

function onApplyFiltersButtonClicked() {
  triggerFetchData();
}

function getUserFilter() {
  let userFilter = new Object();

  if (isNotEmptyArray(filterPanel.value?.coordinators))
    userFilter.id_coordinator_opl = filterPanel.value.coordinators.map((item) => item.id);

  if (isNotEmptyArray(filterPanel.value?.orderStatuses))
    userFilter.id_order_status = filterPanel.value.orderStatuses.map((item) => item.id);

  if (isNotEmptyArray(filterPanel.value?.orderTypes))
    userFilter.id_order_type = filterPanel.value.orderTypes.map((item) => item.id);

  if (filterPanel.value?.priorityChange != null)
    userFilter.priority = filterPanel.value.priorityChange ? 1 : 0;

  if (isNotEmptyArray(filterPanel.value?.directions))
    userFilter.id_direction = filterPanel.value.directions.map((item) => item.id);

  if (isNotEmptyArray(filterPanel.value?.regions))
    userFilter.id_region = filterPanel.value.regions.map((item) => item.id);

  if (isNotEmptyArray(filterPanel.value?.counties))
    userFilter.id_powiat = filterPanel.value.counties.map((item) => item.id);

  if (isNotEmptyArray(filterPanel.value?.geodeticPhases))
    userFilter.id_geodesy_phase = filterPanel.value.geodeticPhases.map((item) => item.id);

  if (filterPanel.value?.userOrderFinishedDate?.startDate != null)
    userFilter.user_order_start_date =
      filterPanel.value.userOrderFinishedDate.startDate + ' 00:00:00';

  if (filterPanel.value?.userOrderFinishedDate?.endDate != null)
    userFilter.user_order_stop_date = filterPanel.value.userOrderFinishedDate.endDate + ' 23:59:59';

  return userFilter;
}

function updateAppliedFilters() {
  appliedFilter.value = JSON.parse(JSON.stringify(filterPanel.value));
}

function filterApplied(filterPanelProp, appliedFilterProp) {
  if (appliedFilterProp != null && filterPanelProp != null)
    return appliedFilterProp === filterPanelProp;
  else return appliedFilterProp == filterPanelProp;
}

function search() {
  triggerFetchData();
}

function triggerFetchData() {
  emit('fetchData', {
    search: searchBox.value,
    filter: getUserFilter()
  });
}

defineExpose({ updateAppliedFilters });
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="row mb-3">
            <div v-if="props.userFilterEnabled" class="col-sm-6">
              <a type="button" class="collapse-link-modified btn btn-w-m btn-default">
                <span v-if="filtersCount > 0" class="badge badge-warning">{{ filtersCount }}</span>
                Filtry &nbsp;
                <i class="fa fa-chevron-down"></i>
              </a>
            </div>

            <div
              v-if="!props.hideSearchBar"
              :class="
                props.longerSearchBar
                  ? 'col-sm-4 ' + (props.userFilterEnabled ? 'offset-sm-2' : 'offset-sm-8')
                  : 'col-sm-3 ' + (props.userFilterEnabled ? 'offset-sm-3' : 'offset-sm-9')
              "
            >
              <div class="input-group">
                <input
                  placeholder="Szukaj.."
                  type="text"
                  class="form-control form-control-sm"
                  v-model="searchBox"
                  v-on:keyup.enter="search"
                />
                <span class="input-group-append">
                  <button @click="search" type="button" class="btn btn-sm btn-primary">
                    <i class="fa fa-search"></i> Szukaj
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="ibox-content" style="display: none">
            <div class="row">
              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <modal-input-field
                  title="Status zlecenia"
                  :titleCssClass="orderStatusesApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.orderStatusesDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_ORDER_STATUSES_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.orderStatuses
                      }
                    })
                  "
                />
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <modal-input-field
                  title="Etap geodezji"
                  :titleCssClass="orderGeodeticPhasesApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.orderGeodeticPhasesDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_ORDER_GEODETIC_PHASES_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.geodeticPhases
                      }
                    })
                  "
                />
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <modal-input-field
                  title="Typ zlecenia"
                  :titleCssClass="orderTypesApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.orderTypesDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_ORDER_TYPES_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.orderTypes
                      }
                    })
                  "
                />
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <div class="form-group">
                  <label
                    class="col-form-label pt-0"
                    :class="priorityChangeApplied ? '' : 'text-warning'"
                    >Priorytet</label
                  >

                  <div class="btn-group btn-group-toggle" style="display: block">
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.priorityChange == null ? 'active' : ''"
                    >
                      <input type="radio" :value="null" v-model="filterPanel.priorityChange" />
                      -
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.priorityChange === true ? 'active' : ''"
                    >
                      <input type="radio" :value="true" v-model="filterPanel.priorityChange" />
                      Tak
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.priorityChange === false ? 'active' : ''"
                    >
                      <input type="radio" :value="false" v-model="filterPanel.priorityChange" />
                      Nie
                    </label>
                  </div>
                </div>
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <modal-input-field
                  title="Rodzaj zlecenia"
                  :titleCssClass="directionsApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.directionsDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_DIRECTIONS_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.directions
                      }
                    })
                  "
                />
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <modal-input-field
                  title="Koordynator"
                  :titleCssClass="coordinatorsApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.coordinatorsDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_USERS_TABLE, {
                      additionalProps: {
                        filter: { url: COORDINATORS_MODAL_URL },
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.coordinators
                      }
                    })
                  "
                />
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <modal-input-field
                  title="Region"
                  :titleCssClass="regionsApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.regionsDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_REGIONS_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.regions
                      }
                    })
                  "
                />
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <modal-input-field
                  title="Powiat"
                  :titleCssClass="countiesApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.countiesDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_COUNTIES_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.counties
                      }
                    })
                  "
                />
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <DateRangeInput
                  inputId="userOrderFinishedDateRangeId"
                  label="Data zakończenia wykonania"
                  :labelCssClass="userOrderFinishedDateApplied ? '' : 'text-warning'"
                  :startDate="filterPanel.userOrderFinishedDate.startDate"
                  :endDate="filterPanel.userOrderFinishedDate.endDate"
                  @startDateChanged="
                    (p1) => {
                      filterPanel.userOrderFinishedDate.startDate = p1;
                      filterPanel.userOrderFinishedDate.endDate =
                        filterPanel.userOrderFinishedDate.endDate ?? p1;
                    }
                  "
                  @endDateChanged="(p2) => (filterPanel.userOrderFinishedDate.endDate = p2)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6 offset-6">
                <button
                  class="btn btn-outline btn-info btn-xs float-right"
                  @click="onApplyFiltersButtonClicked"
                >
                  Zastosuj filtry
                </button>
                <button
                  class="btn btn-outline btn-danger btn-xs float-right mr-1"
                  @click="onClearAllFiltersButtonClicked"
                >
                  Wyczyść filtry
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalTable
      ref="modalTable"
      :showCloseButton="true"
      overrideCloseButtonText="Zatwierdź wybór"
      @onModalCompleted="onModalCompleted"
      @onModalRejected="onModalRejected"
    ></ModalTable>
  </div>
</template>

<style></style>
