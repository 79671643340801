import repository from './repository.js';

export default {
  async getStaticData() {
    return repository
      .get('/order/dashboardCard1Static')
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async getGraphData(year, demElemTypesIds) {
    let formData = new FormData();

    formData.append('year', year.toString());

    if (Array.isArray(demElemTypesIds)) {
      for (let index = 0; index < demElemTypesIds.length; index++) {
        formData.append(`id_dem_elem_type[${index}]`, demElemTypesIds[index]);
      }
    }

    return repository
      .post('/order/dashboardGraph', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getDashboardCard2Data(startDate, endDate, demElemTypesIds) {
    let formData = new FormData();

    formData.append('date_start', startDate);
    formData.append('date_end', endDate);

    if (Array.isArray(demElemTypesIds)) {
      for (let index = 0; index < demElemTypesIds.length; index++) {
        formData.append(`id_dem_elem_type[${index}]`, demElemTypesIds[index]);
      }
    }

    return repository
      .post('/order/dashboardCard2', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getDashboardCard2DataPieCharts(startDate, endDate, demElemTypesIds, usersIds) {
    let formData = new FormData();

    formData.append('date_start', startDate);
    formData.append('date_end', endDate);

    if (Array.isArray(demElemTypesIds)) {
      for (let index = 0; index < demElemTypesIds.length; index++) {
        formData.append(`id_dem_elem_type[${index}]`, demElemTypesIds[index]);
      }
    }

    if (Array.isArray(usersIds)) {
      for (let index = 0; index < usersIds.length; index++) {
        formData.append(`id_user[${index}]`, usersIds[index]);
      }
    }

    return repository
      .post('/order/dashboard2CardGraphs', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getDashboardCard3DataMonths(year, demElemTypesIds, orderRangesIds) {
    let formData = new FormData();

    formData.append('year', year.toString());

    if (Array.isArray(demElemTypesIds)) {
      for (let index = 0; index < demElemTypesIds.length; index++) {
        formData.append(`id_dem_elem_type[${index}]`, demElemTypesIds[index]);
      }
    }

    if (Array.isArray(orderRangesIds)) {
      for (let index = 0; index < orderRangesIds.length; index++) {
        formData.append(`id_order_range[${index}]`, orderRangesIds[index]);
      }
    }

    return repository
      .post('/order/dashboardCard3Collisions', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getDashboardCard3DataOtherTables(startDate, endDate, orderRangesIds) {
    let formData = new FormData();

    formData.append('date_start', startDate);
    formData.append('date_end', endDate);

    if (Array.isArray(orderRangesIds)) {
      for (let index = 0; index < orderRangesIds.length; index++) {
        formData.append(`id_order_range[${index}]`, orderRangesIds[index]);
      }
    }

    return repository
      .post('/order/dashboardCard3Ranges', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  }
};
