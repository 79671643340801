import { onMounted, onUnmounted } from 'vue';
import { useModesStore, MODE_CONFIGS } from '../stores/storeModes';

export function useModes(allowedModes = []) {
  const modesStore = useModesStore();

  function handleKeyDown(event) {
    if (event.key === 'Escape') {
      allowedModes.forEach((modeId) => {
        const modeConfig = MODE_CONFIGS[modeId];
        if (modeConfig?.escapeEnabled && modesStore.isActive(modeId)) {
          modesStore.setMode(null);
          return;
        }
      });
    }

    allowedModes.forEach((modeId) => {
      const modeConfig = MODE_CONFIGS[modeId];
      if (!modeConfig) return;

      const { shortcut } = modeConfig;
      if (
        event.ctrlKey === !!shortcut.ctrl &&
        event.shiftKey === !!shortcut.shift &&
        event.altKey === !!shortcut.alt &&
        event.code === `Key${shortcut.key.toUpperCase()}`
      ) {
        modesStore.toggleMode(modeId);
      }
    });
  }

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });

  return {
    modesStore
  };
}
