<script setup>
import TabNavigation from '../components/utils/TabNavigation.vue';
import { useTabsNavigation } from '../composables/tabsNavigation';

const TABS = [
  {
    routeName: 'dashboardGeneralStats',
    label: 'Ogólna statystyka'
  },
  {
    routeName: 'dashboardActionsStats',
    label: 'Statystyka akcji'
  },
  {
    routeName: 'dashboardTables',
    label: 'Tabele'
  }
];

const { activeTab, navigateToTab } = useTabsNavigation(TABS);
</script>

<template>
  <TabNavigation :tabs="TABS" :activeTab="activeTab" :onTabClick="navigateToTab" />

  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<style scoped></style>
