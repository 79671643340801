<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  title: { type: String, default: '' },
  displayText: { type: String, default: '-' },
  nullText: { type: String, default: '-' },
  required: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  titleCssClass: { type: String, default: '' }
});

const emit = defineEmits(['showModal']);
const displayText = ref(props.displayText);

watch(
  () => props.displayText,
  () => {
    displayText.value =
      typeof props.displayText === 'string' ? props.displayText.trimEnd() : props.displayText;
  }
);

function onShowModal() {
  emit('showModal');
}
</script>

<template>
  <div class="form-group">
    <label :class="titleCssClass">{{ props.title }}</label>
    <select
      onmousedown="(function(e){ e.preventDefault(); })(event, this)"
      onkeypress="(function(e){ e.preventDefault(); })(event, this)"
      class="form-control"
      @click="onShowModal"
      :required="props.required"
      :disabled="props.disabled"
      v-model="displayText"
    >
      <option disabled :value="null" :label="props.nullText"></option>
      <option>{{ props.displayText }}</option>
    </select>
  </div>
</template>

<style scoped></style>
