import Swal from 'sweetalert2';
import { APP_PRIMARY_COLOR } from '../data/constants/appConstants';

export function useModifyTextInSwal() {
  function show(title, currentText, onSucceededAction, additionalParam = null) {
    let newNote = null;

    const swalConfiguration = {
      title,
      icon: 'info',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: `#${APP_PRIMARY_COLOR}`,
      cancelButtonColor: '#f8ac59',
      confirmButtonText: 'Zatwierdź',
      cancelButtonText: 'Anuluj',
      customClass: {
        title: 'swal2-title'
      },
      width: '',
      input: 'textarea',
      inputValue: currentText ?? '',
      inputPlaceholder: 'Uzupełnij pole',
      preConfirm: (inputValue) => {
        newNote = inputValue;
      }
    };

    Swal.fire(swalConfiguration).then((result) => {
      if (result.value != null) {
        onSucceededAction?.(newNote !== '' ? newNote : null, additionalParam);
      }
    });
  }

  return {
    show
  };
}
