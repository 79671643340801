<script setup>
import { computed } from 'vue';
import { useNavigationPanelStore } from '@/stores/navigationPanel';
import { useAuthStore } from '../../stores/auth';
import { ACCESS_KEY_SIDE_MENU_DASHBOARD } from '../../data/constants/authConstants';
import { AVAILABLE_MODES, useModesStore } from '../../stores/storeModes';

const navigationPanelStore = useNavigationPanelStore();
const authStore = useAuthStore();
const modesStore = useModesStore();

const title = computed(() => {
  var items = navigationPanelStore.navigationPanelItems;
  if (!items || items.length <= 0) return '';

  let item = items[items.length - 1];
  let titleToReturn = item.header != undefined ? item.header : item.name;

  return titleToReturn;
});

const titleAppendix = computed(() => {
  var items = navigationPanelStore.navigationPanelItems;
  if (!items || items.length <= 0) return '';

  let item = items[items.length - 1];

  if (item.headerAppendix == null) return '';

  let appendix = item.headerAppendix;

  if (navigationPanelStore.orderNumber != null) {
    appendix = appendix.replace('#orderNumber', navigationPanelStore.orderNumber);
  }

  if (navigationPanelStore.transportOrderNumber != null) {
    appendix = appendix.replace('#transportOrderNumber', navigationPanelStore.transportOrderNumber);
  }

  return appendix;
});

const items = computed(() => {
  return navigationPanelStore.navigationPanelItems;
});

const canAccessDashboard = computed(() => {
  return authStore.canAccess(ACCESS_KEY_SIDE_MENU_DASHBOARD);
});

const isZenMode = computed(() => {
  return modesStore.isActive(AVAILABLE_MODES.ZEN);
});
</script>

<template>
  <div
    class="row wrapper border-bottom white-bg page-heading"
    :style="{ 'padding-bottom': isZenMode ? '0px' : '20px' }"
  >
    <div class="col-lg-10">
      <h2 :style="isZenMode ? { 'margin-bottom': '5px', 'margin-top': '5px' } : {}">
        {{ title }}<b>{{ titleAppendix }}</b>
      </h2>

      <ol v-show="!isZenMode" class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="canAccessDashboard ? '/dashboard' : '/'">Dashboard</router-link>
        </li>

        <li v-for="(item, index) in items" :key="item.name" class="breadcrumb-item">
          <strong v-if="index == items.length - 1">{{ item.name }}</strong>
          <router-link v-else :to="item.link">{{ item.name }}</router-link>
        </li>
      </ol>
    </div>
  </div>
</template>

<style></style>
