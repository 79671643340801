<script setup>
/* eslint-env jquery */
import { ref, computed, watch } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useRouter, useRoute } from 'vue-router';
import NavigationPanel from '@/components/navigation/NavigationPanel.vue';
import { useSideMenuStore } from '../stores/sideMenu';
import {
  ACCESS_KEY_SIDE_MENU_DASHBOARD,
  ACCESS_KEY_SIDE_MENU_GEODESY_PHASES,
  ACCESS_KEY_SIDE_MENU_ORDER_STATUSES,
  ACCESS_KEY_SIDE_MENU_USERS,
  ACCESS_KEY_SIDE_MENU_DICTIONARIES,
  ACCESS_KEY_SIDE_MENU_TRANSPORT_ORDERS,
  ACCESS_KEY_SIDE_MENU_TRANSPORT_ARCHIVED_ORDERS,
  ACCESS_KEY_SIDE_MENU_ARCHIVED_ORDERS,
  ACCESS_KEY_SIDE_MENU_REPORTS_HISTORY,
  ACCESS_KEY_SIDE_MENU_ACTIONS_REGISTER
} from '../data/constants/authConstants.js';
import { RADO_LOGO_SIZE } from '../data/constants/appConstants';
import { createToaster } from '@meforma/vue-toaster';
import { AVAILABLE_MODES, useModesStore } from '../stores/storeModes.js';

const ordersMenuItemHover = ref(false);
const transportOrdersMenuItemHover = ref(false);
const archivedTransportOrdersMenuItemHover = ref(false);
const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();
const sideMenuStore = useSideMenuStore();
const toaster = createToaster({ position: 'top-right', duration: 3000 });
const modesStore = useModesStore();
const isSideMenuOpen = ref(!document.body.classList.contains('mini-navbar'));
const getBackToOpenedSideMenu = ref(false);
const sideMenuObserver = new MutationObserver(() => {
  isSideMenuOpen.value = !document.body.classList.contains('mini-navbar');
});
const isMenuLoading = ref(true);
const routeReady = ref(false);

sideMenuObserver.observe(document.body, { attributes: true, attributeFilter: ['class'] });

const loggedInUser = computed(() => authStore.loggedInUser);

const isDashboardMenuItemActive = computed(() => {
  return route.fullPath.includes('dashboard');
});

const isOrdersMenuItemActive = computed(() => {
  return (
    route.fullPath.includes('orders') &&
    !isOrdersStatusesMenuItemActive.value &&
    !isOrdersGeodesyPhasesMenuItemActive.value
  );
});

const isOrdersStatusesMenuItemActive = computed(() => {
  return route.fullPath.includes('orders?status');
});

const isOrdersGeodesyPhasesMenuItemActive = computed(() => {
  return route.fullPath.includes('orders?geodesyPhase');
});

const isTransportOrdersMenuItemActive = computed(() => {
  return route.fullPath.includes('transportOrders');
});

const isActionsRegisterMenuItemActive = computed(() => {
  return (
    route.fullPath.includes('actionsRegisterTable') ||
    route.fullPath.includes('actionsReportsTable')
  );
});

const isArchivedTransportOrdersMenuItemActive = computed(() => {
  return route.fullPath.includes('archivedTransportOrders');
});

watch(
  () => modesStore.activeMode,
  (newMode) => {
    if (newMode === AVAILABLE_MODES.ZEN && isSideMenuOpen.value) {
      getBackToOpenedSideMenu.value = true;
      toggleSideMenu();
    } else if (newMode !== AVAILABLE_MODES.ZEN && getBackToOpenedSideMenu.value) {
      getBackToOpenedSideMenu.value = false;
      toggleSideMenu();
    }
  }
);

function logOut() {
  authStore.logOut().then(() => {
    router.push({ name: 'login' });
  });
}

function showLoggedInUserDetails() {
  var loggedInUserId = loggedInUser.value?.id;

  if (
    route.name === 'userDetails' &&
    route.params?.id != null &&
    loggedInUserId != null &&
    route.params?.id.toString() === loggedInUserId.toString()
  )
    return;

  router.push({ name: 'userDetails', params: { id: loggedInUserId } });
}

function setSideMenuState(isOpen) {
  if (isOpen) {
    localStorage.removeItem('menuCollapsed');
  } else {
    localStorage.setItem('menuCollapsed', true);
  }
}

function toggleSideMenu() {
  document.querySelector('.navbar-minimalize').click();
}

function onToggleSideMenuButtonClicked() {
  setSideMenuState(!isSideMenuOpen.value);
}

async function onResetTableColumnsButtonClicked() {
  const result = await authStore.resetUserColumnsSettings();

  if (result) {
    toaster.show('Ustawienia tabel zostały zresetowane. Odśwież stronę, aby zobaczyć zmiany.', {
      type: 'success'
    });
  } else {
    toaster.show('Wystąpił błąd podczas resetowania ustawień tabel.', { type: 'error' });
  }
}

router.isReady().then(() => {
  routeReady.value = true;
  $('#side-menu').metisMenu();

  setTimeout(() => {
    isMenuLoading.value = false;
  }, 300);
});
</script>

<template>
  <div id="wrapper">
    <nav
      class="navbar-default navbar-static-side"
      style="background-color: #2f4050"
      role="navigation"
    >
      <div class="sidebar-collapse">
        <div v-if="isMenuLoading" class="menu-loading">
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Ładowanie menu...</span>
          </div>
          <div class="mt-2 text-light">Ładowanie menu...</div>
        </div>
        <ul v-show="!isMenuLoading" class="nav metismenu" id="side-menu">
          <li class="nav-header text-center">
            <div
              v-if="loggedInUser?.firstName && loggedInUser?.lastName"
              class="dropdown profile-element"
            >
              <img
                alt="User"
                class="rounded-circle"
                :src="
                  'https://eu.ui-avatars.com/api/?name=' +
                  loggedInUser.firstName +
                  '+' +
                  loggedInUser.lastName +
                  '&color=2f4050&background=f3f3f4'
                "
              />
              <a data-toggle="dropdown" class="dropdown-toggle" href="#">
                <span class="block m-t-xs font-bold"
                  >{{ loggedInUser.firstName }}
                  {{ loggedInUser.lastName }}
                </span>
                <span class="text-muted text-xs block">
                  {{ loggedInUser.type.name }}
                  <b class="caret"></b>
                </span>
              </a>
              <ul class="dropdown-menu animated fadeIn m-t-xs">
                <li>
                  <a class="dropdown-item" @click="showLoggedInUserDetails">Profil</a>
                </li>
                <li>
                  <a class="dropdown-item" @click="onResetTableColumnsButtonClicked">
                    Resetuj ustawienia tabel
                  </a>
                </li>

                <li class="dropdown-divider"></li>
                <li><a class="dropdown-item" @click="logOut">Wyloguj</a></li>
              </ul>
            </div>
          </li>

          <li
            v-show="authStore.canAccess(ACCESS_KEY_SIDE_MENU_DASHBOARD)"
            :class="[isDashboardMenuItemActive && 'active active2']"
          >
            <a href="#">
              <i class="fa fa-bar-chart-o"></i>
              <span class="nav-label">Dashboard</span>
              <span class="fa arrow"></span>
            </a>
            <ul class="nav nav-second-level collapse">
              <li
                :class="route.name === 'dashboardGeneralStats' ? 'active' : ''"
                style="width: 100%"
              >
                <router-link :to="{ name: 'dashboardGeneralStats' }">Ogólna statystyka</router-link>
              </li>

              <li
                :class="route.name === 'dashboardActionsStats' ? 'active' : ''"
                style="width: 100%"
              >
                <router-link :to="{ name: 'dashboardActionsStats' }">Statystyka akcji</router-link>
              </li>

              <li :class="route.name === 'dashboardTables' ? 'active' : ''" style="width: 100%">
                <router-link :to="{ name: 'dashboardTables' }">Tabele</router-link>
              </li>
            </ul>
          </li>

          <li :class="[isOrdersMenuItemActive && 'active active2']">
            <a
              id="ordersTableMenuElement"
              href="#"
              @mouseover="ordersMenuItemHover = true"
              @mouseleave="ordersMenuItemHover = false"
            >
              <img
                :src="
                  isOrdersMenuItemActive || ordersMenuItemHover
                    ? '/static/img/order-white.png'
                    : '/static/img/order-gray.png'
                "
                alt="Zlecenia"
                width="13"
                height="13"
                style="margin-right: 6px"
              />
              <span class="nav-label">Zlecenia</span>
              <span class="fa arrow"></span>
            </a>
            <ul class="nav nav-second-level collapse">
              <li
                :class="[
                  route.path === '/orders' &&
                    (route.query.mode === 'table' || route.query.mode == undefined) &&
                    'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/orders', query: { mode: 'table' } }"
                  >Tabela</router-link
                >
              </li>

              <li
                :class="[route.path === '/orders' && route.query.mode === 'map' && 'active']"
                style="width: 100%"
              >
                <router-link :to="{ path: '/orders', query: { mode: 'map' } }">Mapa</router-link>
              </li>

              <li
                v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_ARCHIVED_ORDERS)"
                :class="[
                  route.path === '/orders' && route.query.mode === 'archivedTable' && 'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/orders', query: { mode: 'archivedTable' } }"
                  >Zlecenia archiwalne</router-link
                >
              </li>

              <li
                v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_REPORTS_HISTORY)"
                :class="[
                  route.path === '/orders' && route.query.mode === 'reportsHistory' && 'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/orders', query: { mode: 'reportsHistory' } }"
                  >Historia raportów</router-link
                >
              </li>
            </ul>
          </li>

          <li
            v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_ORDER_STATUSES)"
            :class="[isOrdersStatusesMenuItemActive && 'active active2']"
          >
            <a href="#">
              <i class="fa fa-check-square-o"></i>
              <span class="nav-label">Statusy zleceń</span>
              <span class="fa arrow"></span>
            </a>

            <ul class="nav nav-second-level collapse">
              <li
                v-for="orderStatus in sideMenuStore.orderStatuses"
                :key="orderStatus.id"
                :class="[
                  route.path === '/orders' &&
                    route.query.status === orderStatus.id.toString() &&
                    'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/orders', query: { status: orderStatus.id } }">
                  {{ orderStatus.name }}
                </router-link>
              </li>
            </ul>
          </li>

          <li
            v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_GEODESY_PHASES)"
            :class="[isOrdersGeodesyPhasesMenuItemActive && 'active active2']"
          >
            <a href="#">
              <i class="fa fa-globe"></i>
              <span class="nav-label">Etapy geodezji</span>
              <span class="fa arrow"></span>
            </a>

            <ul class="nav nav-second-level collapse">
              <li
                v-for="geodesyPhase in sideMenuStore.orderGeodesyPhases"
                :key="geodesyPhase.id"
                :class="[
                  route.path === '/orders' &&
                    route.query.geodesyPhase === geodesyPhase.id.toString() &&
                    'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/orders', query: { geodesyPhase: geodesyPhase.id } }">
                  {{ geodesyPhase.name }}
                </router-link>
              </li>
            </ul>
          </li>

          <li
            v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_ACTIONS_REGISTER)"
            :class="[isActionsRegisterMenuItemActive && 'active active2']"
          >
            <a id="actionsRegisterMenuElement" href="#">
              <i class="fa fa-align-justify" />
              <span class="nav-label">Rejestr akcji</span>
              <span class="fa arrow"></span>
            </a>
            <ul class="nav nav-second-level collapse">
              <li
                :class="[
                  route.path === '/actionsRegister' && route.query.mode !== 'reports' && 'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/actionsRegister', query: { mode: 'actions' } }"
                  >Tabela akcji</router-link
                >
              </li>

              <li
                :class="[
                  route.path === '/actionsRegister' && route.query.mode === 'reports' && 'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/actionsRegister', query: { mode: 'reports' } }"
                  >Raporty akcji</router-link
                >
              </li>
            </ul>
          </li>

          <li
            v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_USERS)"
            :class="[route.path === '/users' && 'active']"
          >
            <router-link to="/users">
              <i class="fa fa-user-o"></i>
              <span class="nav-label">Użytkownicy</span>
            </router-link>
          </li>

          <li
            v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_DICTIONARIES)"
            :class="[route.path === '/dictionaries' && 'active']"
          >
            <router-link to="/dictionaries">
              <i class="fa fa-edit"></i>
              <span class="nav-label">Słowniki</span>
            </router-link>
          </li>

          <li
            v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_TRANSPORT_ORDERS)"
            :class="[isTransportOrdersMenuItemActive && 'active active2']"
          >
            <a
              id="transportOrdersTableMenuElement"
              href="#"
              @mouseover="transportOrdersMenuItemHover = true"
              @mouseleave="transportOrdersMenuItemHover = false"
            >
              <i class="fa fa-truck" />
              <span class="nav-label">Zlecenia transportowe</span>
              <span class="fa arrow"></span>
            </a>
            <ul class="nav nav-second-level collapse">
              <li
                :class="[
                  route.path === '/transportOrders' && route.query.mode !== 'map' && 'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/transportOrders', query: { mode: 'table' } }"
                  >Tabela</router-link
                >
              </li>

              <li
                :class="[
                  route.path === '/transportOrders' && route.query.mode === 'map' && 'active'
                ]"
                style="width: 100%"
              >
                <router-link :to="{ path: '/transportOrders', query: { mode: 'map' } }"
                  >Mapa</router-link
                >
              </li>
            </ul>
          </li>

          <li
            v-if="authStore.canAccess(ACCESS_KEY_SIDE_MENU_TRANSPORT_ARCHIVED_ORDERS)"
            :class="[isArchivedTransportOrdersMenuItemActive && 'active active2']"
          >
            <a
              id="transportOrdersTableMenuElement"
              href="#"
              @mouseover="archivedTransportOrdersMenuItemHover = true"
              @mouseleave="archivedTransportOrdersMenuItemHover = false"
            >
              <i class="fa fa-car" />
              <span class="nav-label">Archiwalne zlecenia transportowe</span>
              <span class="fa arrow"></span>
            </a>
            <ul class="nav nav-second-level collapse">
              <li
                :class="[
                  route.path === '/archivedTransportOrders' &&
                    route.query.mode !== 'map' &&
                    'active'
                ]"
                style="width: 100%"
              >
                <router-link
                  :to="{
                    path: '/archivedTransportOrders',
                    query: { mode: 'table', archived: true }
                  }"
                  >Tabela</router-link
                >
              </li>

              <li
                :class="[
                  route.path === '/archivedTransportOrders' &&
                    route.query.mode === 'map' &&
                    'active'
                ]"
                style="width: 100%"
              >
                <router-link
                  :to="{ path: '/archivedTransportOrders', query: { mode: 'map', archived: true } }"
                  >Mapa</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>

    <div id="page-wrapper" class="gray-bg">
      <div class="row border-bottom">
        <nav class="navbar navbar-static-top gray-bg" role="navigation" style="margin-bottom: 0">
          <div class="navbar-header">
            <a
              @click="onToggleSideMenuButtonClicked"
              class="navbar-minimalize minimalize-styl-2 btn btn-primary"
              href="#"
              ><i class="fa fa-bars"></i>
            </a>
          </div>

          <ul class="nav navbar-top-links navbar-right">
            <li>
              <img
                src="/static/img/rado_logo.png"
                alt="RADO - logo"
                :width="RADO_LOGO_SIZE[0]"
                :height="RADO_LOGO_SIZE[1]"
              />
            </li>
          </ul>
        </nav>
      </div>

      <NavigationPanel v-if="routeReady && !route.fullPath.includes('dashboard')" />

      <div class="wrapper wrapper-content animated fadeIn">
        <router-view :key="route.fullPath"></router-view>
      </div>
      <div class="footer">
        <div class="pull-right"><strong></strong></div>
        <div><strong>Copyright</strong> DKK Development &copy; {{ new Date().getFullYear() }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.active2 {
  border-left: 4px solid #276fb3;
  background: #293846;
}

.menu-level-forth-a {
  padding-left: 72px;
}

.menu-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding: 20px;
}

.spinner-border {
  width: 2rem;
  height: 2rem;
  border-width: 0.2em;
}
</style>
