<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { createToaster } from '@meforma/vue-toaster';
import FormErrorPanel from '@/components/utils/FormErrorPanel.vue';
import { TOASTER_SERVER_ERROR_MSG } from '../data/constants/toasterMessagesConstants';

const RepositoryAuth = RepositoryFactory.get('auth');
const router = useRouter();
const formErrors = ref([]);
const email = ref(null);
const isLoading = ref(false);

const toaster = createToaster({
  position: 'top-right'
});

async function submitForm(e) {
  e.preventDefault();

  isLoading.value = true;
  var response = await RepositoryAuth.sendResetPasswordLink(email.value);

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { type: 'error' });
  } else {
    if (response) formErrors.value = response;
    else {
      toaster.show('Link został wysłany.', {
        type: 'success'
      });

      router.push({ name: 'login' });
    }
  }

  isLoading.value = false;
}
</script>

<template>
  <div class="passwordBox animated fadeInDown">
    <div class="row">
      <div class="col-md-12">
        <div class="ibox-content">
          <h2 class="font-bold">Zapomniałeś hasło?</h2>

          <p>Wpisz adres email, aby otrzymać link resetujący hasło.</p>

          <form-error-panel :errors="formErrors" />

          <div class="row">
            <div class="col-lg-12">
              <form class="m-t" @submit="submitForm">
                <div class="form-group">
                  <input
                    type="email"
                    v-model="email"
                    class="form-control"
                    placeholder="Wpisz email"
                    required
                    minlength="1"
                    maxlength="100"
                  />
                </div>

                <div v-if="isLoading" class="sk-spinner sk-spinner-circle mb-3 mt-3">
                  <div class="sk-circle1 sk-circle"></div>
                  <div class="sk-circle2 sk-circle"></div>
                  <div class="sk-circle3 sk-circle"></div>
                  <div class="sk-circle4 sk-circle"></div>
                  <div class="sk-circle5 sk-circle"></div>
                  <div class="sk-circle6 sk-circle"></div>
                  <div class="sk-circle7 sk-circle"></div>
                  <div class="sk-circle8 sk-circle"></div>
                  <div class="sk-circle9 sk-circle"></div>
                  <div class="sk-circle10 sk-circle"></div>
                  <div class="sk-circle11 sk-circle"></div>
                  <div class="sk-circle12 sk-circle"></div>
                </div>

                <button type="submit" class="btn btn-primary block full-width m-b">
                  Wyślij link
                </button>
                <div class="text-center" style="font-size: xx-small">
                  Jeśli nie otrzymasz maila w ciągu kilku minut, sprawdź folder ze spamem.
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-6">DKK Development</div>
      <div class="col-md-6 text-right">
        <small>© {{ new Date().getFullYear() }}</small>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
