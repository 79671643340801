<script setup>
import { ref } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import RepoFilesTableGET from '@/components/fileRepositories/RepoFilesTableGET.vue';
import RadioToggle from '@/components/utils/RadioToggle.vue';
import AddDocumentToRepository from './AddDocumentToRepository.vue';
import ActionsRepoFilesTableWithFilters from './ActionsRepoFilesTableWithFilters.vue';
import TransportRepoFilesWithFilters from './TransportRepoFilesWithFilters.vue';
import { handleResponseWithMessages } from '../../helpers/wwwHelper';
import RepoFilesTableGETForAddresses from './RepoFilesTableGETForAddresses.vue';
import Swal from 'sweetalert2';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import { APP_PRIMARY_COLOR } from '../../data/constants/appConstants';
import UpdateButton from '../utils/UpdateButton.vue';

const RepositoryFiles = RepositoryFactory.get('files');
const RepositoryOrders = RepositoryFactory.get('orders');
const props = defineProps({ orderId: { type: Number, default: null }, order: Object });
const activeTabId = ref(0);
const alreadyLoaded = ref({
  documents: true,
  actionsResources: false,
  addresses: false,
  transport: false
});
const documentsKey = ref(0);
const addressesKey = ref(0);
const emit = defineEmits(['refresh']);

function onTabChanged(activeId) {
  activeTabId.value = activeId;

  switch (activeId) {
    case 0:
      alreadyLoaded.value.documents = true;
      break;
    case 1:
      alreadyLoaded.value.actionsResources = true;
      break;
    case 2:
      alreadyLoaded.value.addresses = true;
      break;
    case 3:
      alreadyLoaded.value.transport = true;
      break;
  }
}

async function deleteOrderResource(resourceId) {
  var response = await RepositoryFiles.deleteOrderResource(resourceId);

  handleResponseWithMessages(response, 'Plik został usunięty.', () => {
    documentsKey.value++;
  });
}

async function deleteAddressResource(resourceId) {
  var response = await RepositoryFiles.deleteAddressResource(resourceId);

  handleResponseWithMessages(response, 'Plik został usunięty.', () => {
    addressesKey.value++;
  });
}

function onToggleActionPhotosShareClicked() {
  const message = `Czy na pewno chcesz ${
    props.order?.action_photo_share === 1 ? 'wyłączyć' : 'włączyć'
  } udostępnianie zdjęć z akcji brygadzistom?`;

  Swal.fire(getSwalConfiguration('Zmiana ustawienia', message)).then((result) => {
    if (result.value) {
      toggleActionPhotosShare();
    }
  });
}

async function toggleActionPhotosShare() {
  var response = await RepositoryOrders.toggleActionPhotoShareStatus(props.orderId);

  handleResponseWithMessages(response, 'Status został zmieniony.', () => {
    emit('refresh');
  });
}
</script>

<template>
  <div class="row">
    <div class="mt-1 mb-4 pl-3">
      <RadioToggle
        :initialOptions="[
          { id: 0, title: `Dokumenty`, checked: true },
          { id: 1, title: `Zdjęcia z akcji`, checked: false },
          { id: 2, title: `Adresy`, checked: false },
          { id: 3, title: `Składowanie`, checked: false }
        ]"
        @onChanged="onTabChanged"
      />
    </div>
  </div>

  <!-- span elements for avoiding duplicating children keys TODO check that-->
  <span v-if="alreadyLoaded.documents" v-show="activeTabId === 0">
    <AddDocumentToRepository :orderId="props.orderId" @refresh="documentsKey++" />

    <RepoFilesTableGET
      :key="documentsKey"
      class="mt-4"
      modalId="modalImageContainer2"
      :url="`order/${orderId}/orderFiles`"
      :showBorder="false"
      :longerSearchBar="false"
      :deletingEnabled="true"
      :mobileAppSharingEnabled="true"
      :deleteFunctionOverride="deleteOrderResource"
      @fileShared="documentsKey++"
    />
  </span>

  <span v-if="alreadyLoaded.actionsResources" v-show="activeTabId === 1">
    <div
      v-if="props.order?.action_photo_share !== undefined"
      :style="`
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        gap: 15px;
        border-bottom: 1px dashed #${APP_PRIMARY_COLOR};
        margin-bottom: 20px;
        padding-bottom: 15px;
      `"
    >
      Udostępnienie galerii z akcji brygadzistom:
      <div class="switch">
        <div class="onoffswitch">
          <input
            type="checkbox"
            class="onoffswitch-checkbox"
            :checked="order?.action_photo_share"
          />
          <label class="onoffswitch-label" style="margin-bottom: 0px; cursor: unset">
            <span class="onoffswitch-inner"></span>
            <span class="onoffswitch-switch"></span>
          </label>
        </div>
      </div>

      <UpdateButton :onClickAction="() => onToggleActionPhotosShareClicked()" />
    </div>

    <ActionsRepoFilesTableWithFilters
      :orderId="orderId"
      modalImageContainerId="modalImageContainer1"
    />
  </span>

  <span v-if="alreadyLoaded.addresses" v-show="activeTabId === 2">
    <RepoFilesTableGETForAddresses
      :key="addressesKey"
      modalId="modalImageContainer3"
      :orderId="orderId"
      :showBorder="false"
      :longerSearchBar="false"
      :deletingEnabled="true"
      :deleteFunctionOverride="deleteAddressResource"
    />
  </span>

  <span v-if="alreadyLoaded.transport" v-show="activeTabId === 3">
    <TransportRepoFilesWithFilters
      :orderId="orderId"
      modalImageContainerId="modalImageContainer4"
    />
  </span>
</template>

<style scoped></style>
