<script setup>
import { ref } from 'vue';
import Swal from 'sweetalert2';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import DictionaryTable from '../dictionaries/DictionaryTable.vue';
import ReportsHistoryTableFilterAndSearch from './ReportsHistoryTableFilterAndSearch.vue';
import { getLocalFormattedDateAndTime } from '../../helpers/dateFormatHelper';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import { generateReport } from '../../helpers/registerReportGeneratorHelper';
import { copyToClipboard } from '../../helpers/utilsHelper';
import { handleResponseWithMessages } from '../../helpers/wwwHelper.js';
import { ORDERS_REPORT_READY_STATUS_ID } from '../../data/constants/appConstants.js';

const RepositoryOrders = RepositoryFactory.get('orders');
const reportsHistoryTableFilterAndSearch = ref();
const searchBox = ref('');
const userFilter = ref(null);
const dictTableKey = ref(0);

const tableColumns = [
  { key: 'raport_status', header: 'Status', computed: true },
  { key: 'created_at', header: 'Data', computed: true },
  {
    key: 'is_valid',
    header: 'Zgodny',
    computed: true,
    tdStyle: 'padding-left: 22px;'
  },
  { key: 'author', header: 'Wygenerował', computed: true }
];

// eslint-disable-next-line no-unused-vars
function onDataFetched(dataObject) {
  reportsHistoryTableFilterAndSearch.value.updateAppliedFilters();
}

function onFetchDataWithFiltersRequest({ search, filter }) {
  searchBox.value = search;
  userFilter.value = filter;
  dictTableKey.value++;
}

function onDownloadButtonClicked(reportRow) {
  Swal.fire(
    getSwalConfiguration('Pobieranie raportu', 'Czy na pewno chcesz pobrać wybrany raport?')
  ).then((result) => {
    if (result.value) {
      generateRegisterReport(reportRow);
    }
  });
}

function onCopyLinkButtonClicked(uuid) {
  copyToClipboard(window.location.origin + '/ordersRegisterDownload/' + uuid);
}

function generateRegisterReport(reportRow) {
  RepositoryOrders.getRegisterReportContent(reportRow.id)
    .then((data) => {
      generateReport(data, 'Rejestr zleceń');
    })
    .catch((error) => console.log(error))
    .finally(() => {});
}

function onDeleteReportButtonClicked(reportRow) {
  Swal.fire(
    getSwalConfiguration('Usuwanie raportu', 'Czy na pewno chcesz usunąć wybrany raport?')
  ).then((result) => {
    if (result.value) {
      deleteRegisterReport(reportRow);
    }
  });
}

async function deleteRegisterReport(reportRow) {
  var response = await RepositoryOrders.deleteRegisterReport(reportRow.id);
  handleResponseWithMessages(response, 'Usunięto raport.', () => {
    dictTableKey.value++;
  });
}

function isReportReadyToDownload(report) {
  return report?.raport_status?.id === ORDERS_REPORT_READY_STATUS_ID;
}
</script>

<template>
  <ReportsHistoryTableFilterAndSearch
    ref="reportsHistoryTableFilterAndSearch"
    :longerSearchBar="false"
    :showSearchBar="false"
    @fetchData="onFetchDataWithFiltersRequest"
  />

  <DictionaryTable
    ref="dictionaryTable"
    :key="dictTableKey"
    :showHeader="false"
    :showBorder="false"
    url="registerraport"
    :columns="tableColumns"
    :customButtons="true"
    :showTableElementsInfo="true"
    :searchFromOutside="searchBox"
    :showSearchBar="false"
    :userFilter="userFilter"
    @dataFetched="onDataFetched"
  >
    <template #raport_status="{ element }">
      <span
        class="label label-primary"
        :style="
          element?.raport_status?.color != null
            ? 'background-color:' + element.raport_status.color
            : ''
        "
      >
        {{ element?.raport_status?.name ?? '-' }}
      </span>
    </template>

    <template #created_at="{ element }">
      {{ element.created_at != null ? getLocalFormattedDateAndTime(element.created_at) : '-' }}
    </template>

    <template #is_valid="{ element }">
      <i v-if="element.is_valid === 1" class="fa fa-check text-navy" />
      <i
        v-else-if="element.is_valid === 0"
        class="fa fa-exclamation"
        style="color: red; font-size: 20px; padding-left: 3px"
      />
      <span v-else style="padding-left: 3px">-</span>
    </template>

    <template #author="{ element }">
      {{
        element.author?.firstName && element.author?.lastName
          ? `${element.author.firstName} ${element.author.lastName}`
          : '-'
      }}
    </template>
    <template #buttons="{ element }">
      <button
        type="button"
        class="btn btn-outline btn-primary btn-xs mr-1"
        @click="onCopyLinkButtonClicked(element.uuid)"
        onclick="event.stopPropagation()"
      >
        Skopiuj link
      </button>

      <button
        type="button"
        class="btn btn-outline btn-xs mr-1"
        :class="isReportReadyToDownload(element) ? 'btn-primary' : 'btn-default'"
        @click="onDownloadButtonClicked(element)"
        onclick="event.stopPropagation()"
        :disabled="!isReportReadyToDownload(element)"
      >
        Pobierz
      </button>

      <button
        type="button"
        class="btn btn-outline-danger btn-xs"
        @click="onDeleteReportButtonClicked(element)"
        onclick="event.stopPropagation()"
      >
        Usuń
      </button>
    </template>
  </DictionaryTable>
</template>

<style scoped></style>
