<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { createToaster } from '@meforma/vue-toaster';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import FormErrorPanel from '@/components/utils/FormErrorPanel.vue';
import { TOASTER_SERVER_ERROR_MSG } from '../data/constants/toasterMessagesConstants';

const RepositoryAuth = RepositoryFactory.get('auth');
const formErrors = ref([]);
const newPassword = ref(null);
const newPasswordConfirmed = ref(null);
const isLoading = ref(false);
const route = useRoute();
const router = useRouter();
const toaster = createToaster({
  position: 'top-right'
});

async function submitForm(e) {
  e.preventDefault();

  var path = route.path;
  var indexOfToken = path.indexOf('resetPassword') + 14;

  if (indexOfToken > path.length) {
    formErrors.value.push('Błąd w adresie.');
    return;
  }

  var token = path.substring(indexOfToken, path.length);

  if (newPassword.value == null || newPassword.value !== newPasswordConfirmed.value) {
    formErrors.value.push('Wpisane hasła nie są identyczne.');
  } else {
    isLoading.value = true;

    var response = await RepositoryAuth.resetPassword(token, newPassword.value);

    if (response?.serverError) {
      toaster.show(TOASTER_SERVER_ERROR_MSG, { type: 'error' });
    } else {
      if (response) formErrors.value = response;
      else {
        toaster.show('Hasło zostało zmienione.', {
          type: 'success'
        });

        router.push({ name: 'login' });
      }
    }

    isLoading.value = false;
  }
}
</script>

<template>
  <div class="passwordBox animated fadeInDown">
    <div class="row">
      <div class="col-md-12">
        <div class="ibox-content">
          <h2 class="font-bold">Resetowanie hasła</h2>

          <form-error-panel :errors="formErrors" />

          <div class="row">
            <div class="col-lg-12">
              <form class="m-t" @submit="submitForm">
                <div class="form-group">
                  <input
                    autocomplete="new-password"
                    type="password"
                    placeholder="Wpisz nowe hasło"
                    v-model="newPassword"
                    class="form-control"
                    required
                    minlength="1"
                    maxlength="100"
                  />
                </div>
                <div class="form-group">
                  <input
                    autocomplete="new-password"
                    type="password"
                    placeholder="Powtórz hasło"
                    v-model="newPasswordConfirmed"
                    class="form-control"
                    required
                    minlength="1"
                    maxlength="100"
                  />
                </div>

                <div v-if="isLoading" class="sk-spinner sk-spinner-circle mb-3 mt-3">
                  <div class="sk-circle1 sk-circle"></div>
                  <div class="sk-circle2 sk-circle"></div>
                  <div class="sk-circle3 sk-circle"></div>
                  <div class="sk-circle4 sk-circle"></div>
                  <div class="sk-circle5 sk-circle"></div>
                  <div class="sk-circle6 sk-circle"></div>
                  <div class="sk-circle7 sk-circle"></div>
                  <div class="sk-circle8 sk-circle"></div>
                  <div class="sk-circle9 sk-circle"></div>
                  <div class="sk-circle10 sk-circle"></div>
                  <div class="sk-circle11 sk-circle"></div>
                  <div class="sk-circle12 sk-circle"></div>
                </div>

                <button type="submit" class="btn btn-primary block full-width m-b">
                  Zresetuj hasło
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-6">DKK Development</div>
      <div class="col-md-6 text-right">
        <small>© {{ new Date().getFullYear() }}</small>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
