<script setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { PAGINATION_LIMIT } from '../../data/constants/appConstants.js';
import ServerErrorPanel from '@/components/utils/ServerErrorPanel.vue';
import TableElementsInfo from '@/components/utils/TableElementsInfo.vue';
import PaginationCountPicker from '@/components/utils/PaginationCountPicker.vue';
import { usePaginationStore } from '@/stores/storePagination';
import { Bootstrap4Pagination } from 'laravel-vue-pagination';
import { ORDERS_TABLE_FOR_MAP_PAGINATION_KEY } from '../../data/paginationInjectionKeys';
import { useTableColumns } from '../../composables/tableColumns.js';
import ColumnsVisibilityDropdown from '../utils/ColumnsVisibilityDropdown.vue';
import { isNotEmptyArray } from '../../helpers/utilsHelper.js';

const RepositoryOrders = RepositoryFactory.get('orders');

const props = defineProps({
  selectedOrdersIds: { type: Array, default: () => [] }
});

const router = useRouter();
const isLoading = ref(false);
const ordersObject = ref({});
const searchBox = ref('');
const currentSort = ref({ by: null, direction: null });
const userFilter = ref(null);
const paginationStore = usePaginationStore();

const initialColumns = [
  { key: 'order_number', header: 'Nr', tdStyle: 'white-space: nowrap;' },
  {
    key: 'target',
    header: 'Liczba elementów',
    tdStyle: 'white-space: pre;',
    requiredKeysOverride: []
  },
  {
    key: 'last_worker',
    header: 'Ostatni brygadzista',
    tdStyle: 'white-space: pre;',
    requiredKeysOverride: []
  },
  { key: 'left', header: 'Pozostało do zdemontowania', tdStyle: 'white-space: nowrap;' },
  {
    key: 'order_status',
    header: 'Status zlecenia',
    tdStyle: 'white-space: nowrap; text-align: center;'
  },
  {
    key: 'direction',
    header: 'Rodzaj zlecenia',
    tdStyle: 'white-space: nowrap; text-align: center;'
  }
];

const {
  accessibleColumns,
  visibleColumns,
  isSettingsSaving,
  handleColumnVisibilityChange,
  saveColumnsSettings,
  getNestedValue,
  updateTableData,
  getColumnThStyle,
  getColumnTdStyle,
  draggedColumn,
  dragOverColumn,
  handleDragStart,
  handleDragOver,
  handleColumnDrop,
  resetColumnsOrder
} = useTableColumns('orders_table_for_map', initialColumns);

watch(
  () => props.selectedOrdersIds,
  () => fetchData()
);

function fetchData(page = 1) {
  isLoading.value = true;

  RepositoryOrders.getOrdersForMapObjectByOrdersIds(
    page,
    paginationStore.getPaginationItemsCount(ORDERS_TABLE_FOR_MAP_PAGINATION_KEY),
    searchBox.value,
    currentSort.value.by,
    currentSort.value.direction,
    userFilter.value,
    props.selectedOrdersIds
  )
    .then((data) => {
      ordersObject.value = data;
      updateTableData(ordersObject.value?.data);
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function showDetails(order) {
  let routeData = router.resolve({ name: 'orderDetails', params: { id: order.id } });
  window.open(routeData.href, '_blank');
}

function edit(order) {
  router.push({ name: 'orderAddEdit', params: { id: order.id } });
}

function getTarget(order) {
  if (!order?.target) return '-';

  let target = '';

  for (const property in order.target) {
    target += property + ': ' + order.target[property] + '\n';
  }

  return target;
}

function onItemsPerPageChanged() {
  fetchData();
}
</script>

<template>
  <div class="ibox">
    <div class="ibox-content p-0" :class="{ 'sk-loading': isLoading }" style="border-style: none">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <TableElementsInfo :metaObject="ordersObject?.meta ?? null" />

      <div v-if="isNotEmptyArray(ordersObject?.data)" class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th
                v-for="column in visibleColumns"
                :key="column.key"
                draggable="true"
                @dragstart="handleDragStart(column)"
                @dragover="handleDragOver($event, column)"
                @drop="handleColumnDrop"
                :class="{
                  'drag-over': dragOverColumn === column,
                  'being-dragged': draggedColumn === column
                }"
                :style="[getColumnThStyle(column), { cursor: 'move' }]"
                @click="column.sortable ? sortBy(column.sortKey) : undefined"
              >
                {{ column.header }}
                <span v-if="column.sortable" v-html="getSortingIcon(currentSort, column.sortKey)" />
              </th>

              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="order in ordersObject.data"
              :key="order.id"
              @click="showDetails(order)"
              style="cursor: pointer"
            >
              <template v-for="column in visibleColumns" :key="column.key">
                <td :style="getColumnTdStyle(column)">
                  <!-- Target column -->
                  <template v-if="column.key === 'target'">
                    {{ getTarget(order) }}
                  </template>

                  <!-- Order status column -->
                  <template v-else-if="column.key === 'order_status'">
                    <span
                      class="label label-primary"
                      :style="
                        order?.order_status?.color != null
                          ? 'background-color:' + order.order_status.color
                          : ''
                      "
                    >
                      {{ order?.order_status?.name ?? '-' }}
                    </span>
                  </template>

                  <!-- Last worker column -->
                  <template v-else-if="column.key === 'last_worker'">
                    <template v-if="order.last_worker != null">
                      {{ order.last_worker.firstName }} {{ order.last_worker.lastName }}
                    </template>
                    <template v-else>'-'</template>
                  </template>

                  <!-- Direction column -->
                  <template v-else-if="column.key === 'direction'">
                    <span
                      class="label label-primary"
                      :style="
                        order?.direction?.color != null
                          ? 'background-color:' + order.direction.color
                          : ''
                      "
                    >
                      {{ order?.direction?.name ?? '-' }}
                    </span>
                  </template>

                  <!-- Default column rendering -->
                  <template v-else>
                    {{
                      column.key.includes('.')
                        ? getNestedValue(order, column.key) ?? '-'
                        : order[column.key] ?? '-'
                    }}
                  </template>
                </td>
              </template>

              <td style="white-space: nowrap">
                <div class="float-right">
                  <button
                    class="btn btn-outline btn-primary btn-xs mr-2"
                    type="button"
                    @click="showDetails(order)"
                    onclick="event.stopPropagation()"
                  >
                    Pokaż
                  </button>
                  <button
                    class="btn btn-outline btn-primary btn-xs"
                    type="button"
                    @click="edit(order)"
                    onclick="event.stopPropagation()"
                  >
                    Edytuj
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="ordersObject?.error">
        <ServerErrorPanel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>

      <div
        v-if="isNotEmptyArray(ordersObject?.data)"
        class="d-flex flex-wrap justify-content-between align-items-center mt-3"
        style="gap: 5px"
      >
        <div class="flex-shrink-0 flex-grow-1 justify-items-center">
          <ColumnsVisibilityDropdown
            :columns="accessibleColumns"
            :isSettingsSaving="isSettingsSaving"
            @save="saveColumnsSettings"
            @showAll="handleColumnVisibilityChange('all')"
            @change="handleColumnVisibilityChange"
            @resetOrder="resetColumnsOrder"
          />
        </div>

        <div class="flex-grow-1 d-flex justify-content-center">
          <Bootstrap4Pagination
            v-if="ordersObject?.data"
            class="m-0"
            align="center"
            :data="ordersObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></Bootstrap4Pagination>
        </div>

        <div class="flex-shrink-0 flex-grow-1 justify-items-center">
          <PaginationCountPicker
            :paginationKey="ORDERS_TABLE_FOR_MAP_PAGINATION_KEY"
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
