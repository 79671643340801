<script setup>
import { ref, computed, watch, nextTick, onMounted, unref, shallowRef } from 'vue';
import OrdersTable from '@/components/orders/OrdersTable.vue';
import OrdersMap from '@/components/orders/OrdersMap.vue';
import { useNavigationPanelStore } from '@/stores/navigationPanel';
import { navItems } from '@/helpers/navigationPanelHelper.js';
import OrdersTableFilterAndSearchGlobal from '@/components/orders/OrdersTableFilterAndSearchGlobal.vue';
import { useAuthStore } from '../../stores/auth';
import ReportsHistoryTable from '../reports/ReportsHistoryTable.vue';
import {
  ACCESS_KEY_ORDERS_TABLE_ARCHIVED_TAB,
  ACCESS_KEY_ORDERS_TABLE_REPORTS_HISTORY_TAB
} from '../../data/constants/authConstants';

// props
const props = defineProps({
  status: null,
  geodesyPhase: null,
  mode: null
});

// data
const alreadyLoaded = ref({
  ordersTable: false,
  ordersMap: false,
  archivedOrdersTable: false,
  reportsHistory: false
});

const userFilter = ref(null);
const searchBox = ref('');
const filterPanel = ref({
  coordinators: null,
  orderStatuses: null,
  orderTypes: null,
  priorityChange: null,
  directions: null,
  regions: null,
  boroughs: null,
  counties: null,
  geodesyPhases: null,
  interruptionStatuses: null,
  orderRanges: null,
  claim: null,
  lnPresent: null,
  receiveDate: shallowRef({
    startDate: null,
    endDate: null
  }),
  deadline: shallowRef({
    startDate: null,
    endDate: null
  }),
  acceptanceProtocolCreationDate: shallowRef({
    startDate: null,
    endDate: null
  }),
  workers: null,
  lastWorkers: null,
  createdAtDate: shallowRef({
    startDate: null,
    endDate: null
  }),
  authors: null,
  initiatives: null
});

const filterPanelDisplay = ref({
  coordinatorsDisplayName: null,
  orderStatusesDisplayName: null,
  orderTypesDisplayName: null,
  directiosnDisplayName: null,
  regionsDisplayName: null,
  boroughsDisplayName: null,
  countiesDisplayName: null,
  orderGeodesyPhasesDisplayName: null,
  orderInterruptionStatusesDisplayName: null,
  orderRangesDisplayName: null,
  workersDisplayName: null,
  lastWorkersDisplayName: null,
  authorsDisplayName: null,
  initiativesDisplayName: null
});

const appliedFilter = ref(new Object());
const mapTabActive = ref(false);
const ordersMapChild = ref(null);
const ordersTableChild = ref();
const archivedOrdersTableChild = ref();
const navigationPanelStore = useNavigationPanelStore();
const authStore = useAuthStore();

const mapProperlyLoaded = ref(false);
const mapDataFetched = ref(false);
const reportsHistoryComponentKey = ref(0);

const mode = ref(null);
mode.value = unref(props.mode) ?? 'table';

watch(
  () => props.mode,
  () => {
    mode.value = unref(props.mode);
  }
);

watch(mode, () => {
  onTabClicked(mode.value);
  switchTabs();
});

const ordersTableUrl = computed(() => {
  return authStore.isSubcontractorLoggedIn ? '/order/register_subcontractor' : '/order/register';
});

const ordersTableUrlArchived = computed(() => {
  return authStore.isSubcontractorLoggedIn
    ? '/order/register_subcontractor_archived'
    : '/order/register_archived';
});

// created
navigationPanelStore.setNavigationPanelItems(navItems.ORDERS_LIST);

switch (mode.value) {
  case 'table':
    alreadyLoaded.value.ordersTable = true;
    break;
  case 'map':
    alreadyLoaded.value.ordersMap = true;
    break;
  case 'archivedTable':
    alreadyLoaded.value.archivedOrdersTable = true;
    break;
  case 'reportsHistory':
    alreadyLoaded.value.reportsHistory = true;
    break;
}

mapTabActive.value = mode.value === 'map';

if (props.status != null || props.geodesyPhase != null) {
  assignInitialFilter();
}

// mounted
onMounted(() => {
  switchTabs();
  initializeOnTab2VisibleEvent();
});

//methods
function initializeOnTab2VisibleEvent() {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const setBoudsNeeded = !mapProperlyLoaded.value;
        ordersMapChild.value?.refreshMapBounds(setBoudsNeeded);

        if (setBoudsNeeded && mapDataFetched.value) {
          mapProperlyLoaded.value = true;
        }
      }
    });
  });
  const elementToObserve = document.getElementById('tab-2');
  observer.observe(elementToObserve);
}

function onTabClicked(tabId) {
  switch (tabId) {
    case 'table':
      alreadyLoaded.value.ordersTable = true;
      break;
    case 'map':
      alreadyLoaded.value.ordersMap = true;
      break;
    case 'archivedTable':
      alreadyLoaded.value.archivedOrdersTable = true;
      break;
    case 'reportsHistory':
      reportsHistoryComponentKey.value++;
      alreadyLoaded.value.reportsHistory = true;
      break;
  }

  mapTabActive.value = tabId === 'map';
}

function switchTabs() {
  let tabIdToActivate = null;

  switch (mode.value) {
    case 'table':
      alreadyLoaded.value.ordersTable = true;
      tabIdToActivate = 1;
      break;
    case 'map':
      alreadyLoaded.value.ordersMap = true;
      tabIdToActivate = 2;
      break;
    case 'archivedTable':
      alreadyLoaded.value.archivedOrdersTable = true;
      tabIdToActivate = 3;
      break;
    case 'reportsHistory':
      alreadyLoaded.value.reportsHistory = true;
      tabIdToActivate = 4;
      break;
  }

  if (tabIdToActivate == null) return;

  let tabIdIterator = 1;
  let headerElement = null;
  let element = null;

  do {
    headerElement = document.getElementById('tab-' + tabIdIterator.toString() + '-header');
    element = document.getElementById('tab-' + tabIdIterator.toString());

    if (headerElement == null || element == null) break;

    if (tabIdIterator === tabIdToActivate) {
      headerElement.classList.add('active');
      element.classList.add('active');
    } else {
      headerElement.classList.remove('active');
      element.classList.remove('active');
    }

    tabIdIterator++;
  } while (headerElement != null && element != null);
}

function onFetchDataWithFiltersRequest({ filter }) {
  userFilter.value = filter;

  if (alreadyLoaded.value.ordersTable) {
    nextTick(() => {
      ordersTableChild.value?.fetchData();
    });
  }

  if (alreadyLoaded.value.ordersMap) {
    nextTick(() => {
      ordersMapChild.value?.fetchData(1, false);
    });
  }

  if (alreadyLoaded.value.archivedOrdersTable) {
    nextTick(() => {
      archivedOrdersTableChild.value?.fetchData();
    });
  }
}

function assignInitialFilter() {
  userFilter.value = new Object();

  if (filterPanel.value == null) filterPanel.value = new Object();

  if (filterPanelDisplay.value == null) filterPanelDisplay.value = new Object();

  assignOrderStatusInitialFilter();
  assignOrderGeodesyPhaseInitialFilter();
}

function updateFiltersAfterFetch() {
  appliedFilter.value = Object.assign({}, filterPanel.value);
}

function updateFiltersAfterMapFetch() {
  updateFiltersAfterFetch();
  mapDataFetched.value = true;

  if (mapTabActive.value) {
    mapProperlyLoaded.value = true;
  }
}

function onSearchChangedInFilterPanel(newSearchValue) {
  searchBox.value = newSearchValue;
}

function assignOrderStatusInitialFilter() {
  if (props.status == null) return;

  userFilter.value.id_order_status = [props.status];
  userFilter.value.left_menu = true;

  let orderStatusObj = {};
  orderStatusObj.id = props.status;
  //TODO
  switch (props.status) {
    case '1':
      orderStatusObj.name = 'Nowe zlecenie';
      break;
    case '2':
      orderStatusObj.name = 'W produkcji';
      break;
    case '3':
      orderStatusObj.name = 'Przerwany demontaż';
      break;
    case '4':
      orderStatusObj.name = 'Przerwane';
      break;
    case '5':
      orderStatusObj.name = 'Anulowane';
      break;
    case '6':
      orderStatusObj.name = 'Geodezja';
      break;
    case '7':
      orderStatusObj.name = 'Zakończony demontaż do zabrania';
      break;
    case '8':
      orderStatusObj.name = 'Zakończony demontaż PO';
      break;
    case '9':
      orderStatusObj.name = 'Rozliczenie';
      break;
    case '10':
      orderStatusObj.name = 'Zrealizowany i rozliczony demontaż';
      break;
    case '11':
      orderStatusObj.name = 'Weryfikacja RADO';
      break;
  }

  filterPanel.value.orderStatuses = [orderStatusObj];
  filterPanelDisplay.value.orderStatusesDisplayName = orderStatusObj?.name ?? '';
}

function assignOrderGeodesyPhaseInitialFilter() {
  if (props.geodesyPhase == null) return;

  userFilter.value.id_geodesy_phase = [props.geodesyPhase];

  let orderGeodesyPhaseObj = {};
  orderGeodesyPhaseObj.id = props.geodesyPhase;

  switch (props.geodesyPhase) {
    case '1':
      orderGeodesyPhaseObj.name = 'Do zlecenia geodecie';
      break;
    case '2':
      orderGeodesyPhaseObj.name = 'W realizacji';
      break;
    case '3':
      orderGeodesyPhaseObj.name = 'Przesłano do weryfikacji  do OPL';
      break;
  }

  filterPanel.value.geodesyPhases = [orderGeodesyPhaseObj];
  filterPanelDisplay.value.orderGeodesyPhasesDisplayName = orderGeodesyPhaseObj?.name ?? '';
}
</script>

<template>
  <div class="col-lg-12">
    <div class="tabs-container">
      <ul class="nav nav-tabs" role="tablist">
        <li @click="onTabClicked('table')">
          <a class="nav-link active" id="tab-1-header" data-toggle="tab" href="#tab-1"> Zlecenia</a>
        </li>
        <li @click="onTabClicked('map')">
          <a class="nav-link" id="tab-2-header" data-toggle="tab" href="#tab-2">
            Mapa ze zleceniami
          </a>
        </li>
        <li
          v-if="authStore.canAccess(ACCESS_KEY_ORDERS_TABLE_ARCHIVED_TAB)"
          @click="onTabClicked('archivedTable')"
        >
          <a class="nav-link" id="tab-3-header" data-toggle="tab" href="#tab-3">
            Zlecenia archiwalne
          </a>
        </li>
        <li
          v-if="authStore.canAccess(ACCESS_KEY_ORDERS_TABLE_REPORTS_HISTORY_TAB)"
          @click="onTabClicked('reportsHistory')"
        >
          <a class="nav-link" id="tab-4-header" data-toggle="tab" href="#tab-4">
            Historia raportów
          </a>
        </li>
      </ul>
      <div class="tab-content">
        <div role="tabpanel" id="tab-1" class="tab-pane active">
          <div class="panel-body">
            <OrdersTable
              v-if="alreadyLoaded.ordersTable"
              ref="ordersTableChild"
              :filter="{ url: ordersTableUrl }"
              :addButton="true"
              :editButton="false"
              :showBorder="false"
              :showTitle="false"
              :globalFilterPanel="true"
              :filterFromParent="userFilter"
              :searchFromParent="searchBox"
              useCaseId="mainRegister"
              @afterSuccessfulFetch="updateFiltersAfterFetch"
            >
              <template v-slot:globalFilterPanel>
                <OrdersTableFilterAndSearchGlobal
                  ref="filterChildForTable"
                  @fetchData="onFetchDataWithFiltersRequest"
                  :searchBox="searchBox"
                  :filterPanel="filterPanel"
                  :filterPanelDisplay="filterPanelDisplay"
                  :appliedFilter="appliedFilter"
                  @searchChanged="onSearchChangedInFilterPanel"
                  @updateFilterPanel="
                    (value) => {
                      filterPanel = value;
                    }
                  "
                  @updateFilterPanelDisplay="
                    (value) => {
                      filterPanelDisplay = value;
                    }
                  "
                />
              </template>
            </OrdersTable>
          </div>
        </div>
        <div role="tabpanel" id="tab-2" class="tab-pane">
          <div class="panel-body">
            <OrdersMap
              v-if="alreadyLoaded.ordersMap"
              ref="ordersMapChild"
              :filterFromParent="userFilter"
              :mapTabActive="mapTabActive"
              :searchFromParent="searchBox"
              @afterSuccessfulFetch="updateFiltersAfterMapFetch"
            >
              <template v-slot:globalFilterPanel>
                <OrdersTableFilterAndSearchGlobal
                  ref="filterChildForMap"
                  :hideSearchBar="false"
                  :oneFilterPerRow="true"
                  @fetchData="onFetchDataWithFiltersRequest"
                  :searchBox="searchBox"
                  :filterPanel="filterPanel"
                  :filterPanelDisplay="filterPanelDisplay"
                  :appliedFilter="appliedFilter"
                  customSearchBarCssClass="col-sm-6"
                  @updateFilterPanel="
                    (value) => {
                      filterPanel = value;
                    }
                  "
                  @updateFilterPanelDisplay="
                    (value) => {
                      filterPanelDisplay = value;
                    }
                  "
                  @searchChanged="onSearchChangedInFilterPanel"
                />
              </template>
            </OrdersMap>
          </div>
        </div>
        <div
          v-if="authStore.canAccess(ACCESS_KEY_ORDERS_TABLE_ARCHIVED_TAB)"
          role="tabpanel"
          id="tab-3"
          class="tab-pane"
        >
          <div class="panel-body">
            <OrdersTable
              v-if="alreadyLoaded.archivedOrdersTable"
              ref="archivedOrdersTableChild"
              :filter="{ url: ordersTableUrlArchived }"
              :addButton="false"
              :editButton="false"
              :showBorder="false"
              :showTitle="false"
              :globalFilterPanel="true"
              :filterFromParent="userFilter"
              :searchFromParent="searchBox"
              useCaseId="mainRegisterArchived"
              @afterSuccessfulFetch="updateFiltersAfterFetch"
            >
              <template v-slot:globalFilterPanel>
                <OrdersTableFilterAndSearchGlobal
                  ref="filterChildForArchivedTable"
                  @fetchData="onFetchDataWithFiltersRequest"
                  :searchBox="searchBox"
                  :filterPanel="filterPanel"
                  :filterPanelDisplay="filterPanelDisplay"
                  :appliedFilter="appliedFilter"
                  @searchChanged="onSearchChangedInFilterPanel"
                  @updateFilterPanel="
                    (value) => {
                      filterPanel = value;
                    }
                  "
                  @updateFilterPanelDisplay="
                    (value) => {
                      filterPanelDisplay = value;
                    }
                  "
                />
              </template>
            </OrdersTable>
          </div>
        </div>
        <div
          v-if="authStore.canAccess(ACCESS_KEY_ORDERS_TABLE_REPORTS_HISTORY_TAB)"
          role="tabpanel"
          id="tab-4"
          class="tab-pane"
        >
          <div class="panel-body">
            <ReportsHistoryTable
              :key="reportsHistoryComponentKey"
              v-if="alreadyLoaded.reportsHistory"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
