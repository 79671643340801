import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export function useTabsNavigation(tabsConfig) {
  const router = useRouter();
  const route = useRoute();

  const activeTab = computed(() => {
    return tabsConfig.find((tab) => route.name === tab.routeName) ?? tabsConfig[0];
  });

  function navigateToTab(path) {
    router.push({ name: path.routeName });
  }

  return {
    activeTab,
    navigateToTab
  };
}
