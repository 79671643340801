import { getDateWithoutTime, getLocalFormattedDateAndTime } from '@/helpers/dateFormatHelper.js';
import { createToaster } from '@meforma/vue-toaster';

export function isNotEmptyArray(object) {
  return Array.isArray(object) && object.length > 0;
}

export function showFileInNewTab(fileToShow) {
  if (fileToShow?.resource?.path != null) {
    window.open(import.meta.env.VITE_VUE_APP_IMAGES_URL + fileToShow.resource.path, '_blank');
  }
}

export function getCurrentDate() {
  return getDateWithoutTime(getLocalFormattedDateAndTime(new Date().toJSON()));
}

export function copyToClipboard(textToCopy, overwriteMessage = null) {
  navigator.clipboard.writeText(textToCopy);
  const toaster = createToaster({ position: 'top-right' });
  const message = overwriteMessage ?? 'Skopiowano do schowka: ' + textToCopy;

  toaster.show(message, { duration: 3000, type: 'info' });
}

export function generateRandomString(length, numbers = false, specialCharacters = false) {
  let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  if (numbers) chars += '0123456789';
  if (specialCharacters) chars += '!@#$%^&*()';

  const charLength = chars.length;
  let result = '';

  for (var i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }

  return result;
}

export function logFormData(formData) {
  for (var pair of formData.entries()) {
    console.log(pair[0] + ': ' + pair[1]);
  }
}

export function pushSafe(array, obj) {
  if (Array.isArray(array)) {
    array.push(obj);
  } else {
    array = [obj];
  }

  return array;
}

export function isNullOrEmptyString(text) {
  return !text || text.trim().length === 0;
}

export function isNonEmptyString(text) {
  return !isNullOrEmptyString(text);
}

export function computeProgress(done, all, decimalPlaces = 1) {
  const doneFloat = parseFloat(done);
  const allFloat = parseFloat(all);

  if (isNaN(doneFloat) || isNaN(allFloat)) return undefined;
  if (allFloat === 0) return '0%';

  return ((doneFloat / allFloat) * 100).toFixed(decimalPlaces) + '%';
}

export function formatRemainingTimeInPolish(seconds) {
  const hourForms = ['godzina', 'godziny', 'godzin'];
  const minuteForms = ['minuta', 'minuty', 'minut'];
  const secondForms = ['sekunda', 'sekundy', 'sekund'];

  const getPolishForm = (value, forms) => {
    if (value === 1) return forms[0];
    if (
      (value >= 2 && value <= 4) ||
      (value >= 22 && value <= 24) ||
      (value >= 32 && value <= 34) ||
      (value >= 42 && value <= 44) ||
      (value >= 52 && value <= 54)
    )
      return forms[1];
    return forms[2];
  };

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let result = '';

  if (hours > 0) {
    result += `${hours} ${getPolishForm(hours, hourForms)}`;
  }

  if (minutes > 0) {
    if (hours > 0) {
      result += ` i `;
    }
    result += `${minutes} ${getPolishForm(minutes, minuteForms)}`;
  }

  if (remainingSeconds > 0) {
    if (hours > 0 || minutes > 0) {
      result += ` i `;
    }
    result += `${remainingSeconds} ${getPolishForm(remainingSeconds, secondForms)}`;
  }

  if (hours === 0 && minutes === 0 && remainingSeconds === 0) {
    result += `${remainingSeconds} ${getPolishForm(remainingSeconds, secondForms)}`;
  }

  return result;
}

export function trimString(str, maxChars = 100) {
  return isNonEmptyString(str) && str.length > maxChars ? str.slice(0, maxChars) + '...' : str;
}

export function waitUntil(condition, checkFrequencyMs = 50) {
  return new Promise((resolve) => {
    const check = () => {
      if (condition) {
        resolve();
      } else {
        setTimeout(check, checkFrequencyMs);
      }
    };
    check();
  });
}

/**
 * Gets the maximum length of nested arrays within an array
 * @param {Array} items - The array to check for nested arrays
 * @returns {number} The maximum length found among all nested arrays, or 1 for non-array items, or 0 if input is not an array
 * @example
 * getMaxArrayLength([[1,2], [1,2,3], 4]) // returns 3
 * getMaxArrayLength(['a', [1,2], []]) // returns 2
 * getMaxArrayLength('not an array') // returns 0
 */
export const getMaxArrayLength = (items) => {
  if (!Array.isArray(items)) return 0;

  return Math.max(...items.map((item) => (Array.isArray(item) ? item.length : 1)));
};

export function removeDiacritics(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified
  });
}

export function getFileWithCleanName(file) {
  if (file == null || file.name == null) {
    console.warn('getFileWithCleanName(): file or its new is null.');
    return null;
  }

  return renameFile(file, removeDiacritics(file.name));
}

export function getImageResolution(file) {
  return new Promise((resolve, reject) => {
    if (!file) {
      return reject(new Error('getImageResolution(): No file provided.'));
    }

    if (!(file instanceof Blob || file instanceof File)) {
      return reject(new Error('getImageResolution(): Provided argument is not a Blob or File.'));
    }

    if (!file.type.startsWith('image')) {
      return reject(new Error('getImageResolution(): The file is not a valid image.'));
    }

    const reader = new FileReader();

    reader.onerror = function () {
      reject(new Error('getImageResolution(): Failed to read the file.'));
    };

    reader.onload = function (event) {
      const img = new Image();

      img.onerror = function () {
        reject(new Error('getImageResolution(): Failed to load the image.'));
      };

      img.onload = function () {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        resolve({ width, height });
      };

      img.src = event.target.result;
    };

    reader.readAsDataURL(file);
  });
}

/**
 * Creates a debounced function that delays invoking the provided function
 * until after the specified wait time has elapsed since the last invocation.
 * @param {Function} func - The function to debounce
 * @param {number} wait - The number of milliseconds to delay
 * @return {Function} The debounced function
 */
export function debounce(func, wait = 300) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}
