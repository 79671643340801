<script setup>
import { ref, unref } from 'vue';
import { useRouter } from 'vue-router';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { PAGINATION_LIMIT } from '../../data/constants/appConstants.js';
import {
  ordersSortingOptions as sortingOptions,
  getSortingIcon,
  getSortingDirection
} from '@/helpers/sortTableHelper.js';
import PaginationCountPicker from '@/components/utils/PaginationCountPicker.vue';
import OrdersTableFilterAndSearch from '@/components/orders/OrdersTableFilterAndSearch.vue';
import { getDateWithoutTime } from '@/helpers/dateFormatHelper.js';
import ServerErrorPanel from '@/components/utils/ServerErrorPanel.vue';
import TableElementsInfo from '@/components/utils/TableElementsInfo.vue';
import { exportXlsx } from '@/helpers/xlsxExporter.js';
import { usePaginationStore } from '@/stores/storePagination';
import { Bootstrap4Pagination } from 'laravel-vue-pagination';
import { MAIN_ORDERS_TABLE_PAGINATION_KEY } from '../../data/paginationInjectionKeys';
import { isNonEmptyString, isNotEmptyArray, trimString } from '../../helpers/utilsHelper';
import { useAuthStore } from '../../stores/auth';
import {
  ACCESS_KEY_ORDERS_TABLE_EXPORT_BUTTON,
  ACCESS_KEY_ORDERS_TABLE_NEW_ORDER_BUTTON,
  ACCESS_KEY_ORDERS_TABLE_REQUEST_REPORT_BUTTON
} from '../../data/constants/authConstants';
import Swal from 'sweetalert2';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import { TOASTER_SERVER_ERROR_MSG } from '../../data/constants/toasterMessagesConstants';
import { createToaster } from '@meforma/vue-toaster';
import { useTableColumns } from '../../composables/tableColumns.js';
import ColumnsVisibilityDropdown from '../utils/ColumnsVisibilityDropdown.vue';

const RepositoryOrders = RepositoryFactory.get('orders');

const props = defineProps({
  filter: { type: Object, default: null },
  selectableItems: { type: Boolean, default: false },
  showHeader: { type: Boolean, default: true },
  showBorder: { type: Boolean, default: true },
  longerSearchBar: { type: Boolean, default: false },
  addButton: { type: Boolean, default: false },
  editButton: { type: Boolean, default: false },
  showTitle: { type: Boolean, default: true },
  userFilterEnabled: { type: Boolean, default: true },
  hideWorkerFilter: { type: Boolean, default: false },
  globalFilterPanel: { type: Boolean, default: false },
  filterFromParent: { type: Object, default: null },
  searchFromParent: { type: String, default: '' },
  multiSelections: { type: Boolean, default: false },
  initiallyPickedItems: { type: Array, default: null },
  useCaseId: { type: String, default: null }
});

const emit = defineEmits(['afterSuccessfulFetch', 'on-completed']);

const router = useRouter();
const isLoading = ref(false);
const ordersObject = ref({});
const searchBox = ref('');
const currentSort = ref({ by: null, direction: null });
const userFilter = ref(null);
const paginationStore = usePaginationStore();
const ordersTableFilterAndSearchChildComponent = ref(null);
const authStore = useAuthStore();
const toaster = createToaster({ position: 'top-right' });
const pickedItems = ref([]);

const initialColumns = [
  {
    key: 'order_number',
    header: 'Nr zlecenia',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'direction',
    header: 'Rodzaj zlecenia',
    thStyle: 'white-space: nowrap; text-align: center;',
    tdStyle: 'white-space: nowrap; text-align: center;'
  },
  {
    key: 'order_range_complete',
    header: 'Zakres prac',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'user_order_count',
    header: 'Wykonawcy',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'target',
    header: 'Elementy do likwidacji',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'claim',
    header: 'Roszczenie',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'ln_present',
    header: 'LN',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'gmina.name',
    header: 'Gmina',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'region.name',
    header: 'Region',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'addresses',
    header: 'Lokalizacja',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  { key: 'coordinator_opl', header: 'Koordynator', tdStyle: 'white-space: nowrap;' },
  {
    key: 'receive_date',
    header: 'Wpłynęło do RADO',
    sortable: false, // sorting is done by the server
    sortKey: sortingOptions.RECEIVE_DATE,
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'deadline',
    header: 'Termin realizacji',
    sortable: false, // sorting is done by the server
    sortKey: sortingOptions.DEADLINE,
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'order_status',
    header: 'Status zlecenia',
    thStyle: 'white-space: nowrap; text-align: center;',
    tdStyle: 'white-space: nowrap; text-align: center;'
  },
  {
    key: 'disassembly_status',
    header: 'Status wykonania',
    thStyle: 'white-space: nowrap; text-align: center;',
    tdStyle: 'white-space: nowrap; text-align: center;'
  },
  {
    key: 'order_initiative.name',
    header: 'Inicjatywa',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'disassembly_start_date',
    header: 'Start wykonania',
    thStyle: 'white-space: nowrap',
    tdStyle: 'white-space: nowrap',
    requiredKeysOverride: ['start']
  },
  {
    key: 'disassembly_end_date',
    header: 'Koniec wykonania',
    thStyle: 'white-space: nowrap',
    tdStyle: 'white-space: nowrap',
    requiredKeysOverride: ['stop']
  },
  {
    key: 'created_at',
    header: 'Utworzone',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  },
  {
    key: 'created_by',
    header: 'Autor',
    thStyle: 'white-space: nowrap;',
    tdStyle: 'white-space: nowrap;'
  }
];

const {
  accessibleColumns,
  visibleColumns,
  isSettingsSaving,
  handleColumnVisibilityChange,
  saveColumnsSettings,
  getNestedValue,
  updateTableData,
  getColumnThStyle,
  getColumnTdStyle,
  draggedColumn,
  dragOverColumn,
  handleDragStart,
  handleDragOver,
  handleColumnDrop,
  resetColumnsOrder
} = useTableColumns(
  'orders_table' + (isNonEmptyString(props.useCaseId) ? `_${props.useCaseId}` : ''),
  initialColumns
);

function fetchData(page = 1) {
  isLoading.value = true;

  RepositoryOrders.getOrdersObject(
    page,
    paginationStore.getPaginationItemsCount(MAIN_ORDERS_TABLE_PAGINATION_KEY),
    props.globalFilterPanel ? props.searchFromParent : searchBox.value,
    currentSort.value.by,
    currentSort.value.direction,
    props.filter,
    props.globalFilterPanel ? props.filterFromParent : userFilter.value
  )
    .then((data) => {
      if (props.multiSelections) {
        data.data.forEach((element) => {
          element.picked = pickedItems.value.find((item) => item.id == element.id) != null;
        });
      }

      ordersObject.value = data;
      emit('afterSuccessfulFetch');
      ordersTableFilterAndSearchChildComponent.value?.updateAppliedFilters();
      updateTableData(ordersObject.value?.data);
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
      //eslint-disable-next-line no-undef
      $('[data-toggle="tooltip"]').tooltip({ placement: 'right' });
    });
}

function sortBy(newSortBy) {
  currentSort.value.direction = getSortingDirection(currentSort.value, newSortBy);
  currentSort.value.by = newSortBy;
  fetchData();
}

function addNewOrder() {
  router.push({ name: 'orderAddEdit' });
}

function showDetails(order) {
  let routeData = router.resolve({ name: 'orderDetails', params: { id: order.id } });
  window.open(routeData.href, '_blank');
}

function edit(order) {
  router.push({ name: 'orderAddEdit', params: { id: order.id } });
}

function onFetchDataWithFiltersRequest({ search, filter }) {
  searchBox.value = search;
  userFilter.value = filter;
  fetchData();
}

function exportOrders() {
  isLoading.value = true;

  RepositoryOrders.exportOrders(
    props.globalFilterPanel ? props.searchFromParent : searchBox.value,
    currentSort.value.by,
    currentSort.value.direction,
    props.globalFilterPanel ? props.filterFromParent : userFilter.value
  )
    .then((data) => {
      data = data.replace('ID', 'Id');
      exportXlsx(convertCsvToHeadersAndData(data), 'Eksport zleceń', 'Zlecenia');
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function pickItem(order) {
  if (!props.multiSelections) {
    emit('on-completed', order);
    return;
  }

  order.picked = !order.picked;

  const orderFoundInPicked = pickedItems.value.find((item) => item.id == order.id);

  if (order.picked && orderFoundInPicked == null) {
    pickedItems.value.push(order);
  } else if (!order.picked && orderFoundInPicked != null) {
    const index = pickedItems.value.indexOf(orderFoundInPicked);
    pickedItems.value.splice(index, 1);
  }

  emit('on-completed', order, unref(pickedItems));
}

function convertCsvToHeadersAndData(csv) {
  let rows = [];
  let csvRows = csv.toString().split('\n');
  csvRows = csvRows.filter((csvRow) => csvRow !== '');

  csvRows.forEach((csvRow) => {
    csvRow = csvRow.replace(/"/g, '');
    let row = csvRow.toString().split('&');

    let rowConverted = row.map((el) => {
      if (isNaN(el)) return el;
      let parsed = parseFloat(el);

      if (!isNaN(parsed)) return parsed;
      else return el;
    });

    rows.push(rowConverted);
  });

  return rows;
}

function onItemsPerPageChanged() {
  fetchData();
}

function onRequestReportButtonClicked() {
  Swal.fire(
    getSwalConfiguration(
      'Zapytanie o raport',
      'Czy na pewno chcesz wysłać zapytanie o wygenerowanie raportu?'
    )
  ).then((result) => {
    if (result.value) {
      requestReportGeneration();
    }
  });
}

async function requestReportGeneration() {
  let searchValue = props.globalFilterPanel ? props.searchFromParent : searchBox.value;
  let userFilterValue = props.globalFilterPanel ? props.filterFromParent : userFilter.value;

  var response = await RepositoryOrders.requestOrdersReport(
    authStore.loggedInUser.id,
    searchValue,
    userFilterValue
  );

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { duration: 3000, type: 'error' });
  } else if (response?.success === true) {
    toaster.show('Wysłano zapytanie o wygenerowanie raportu.', { duration: 3000, type: 'success' });
  }
}

// created
pickedItems.value = [];

if (Array.isArray(props.initiallyPickedItems)) {
  props.initiallyPickedItems.forEach((element) => {
    pickedItems.value.push(element);
  });
}

fetchData();

defineExpose({ fetchData });
</script>

<template>
  <div class="ibox">
    <div
      v-if="props.showHeader"
      class="ibox-title"
      :style="props.showBorder ? '' : 'border-style: none'"
    >
      <h5 v-if="props.showTitle">Zlecenia</h5>
      <div v-if="props.addButton" class="ibox-tools">
        <a
          v-if="authStore.canAccess(ACCESS_KEY_ORDERS_TABLE_NEW_ORDER_BUTTON)"
          @click="addNewOrder"
          class="btn btn-primary btn-xs"
          >+ Nowe zlecenie</a
        >
        <a
          v-if="authStore.canAccess(ACCESS_KEY_ORDERS_TABLE_EXPORT_BUTTON)"
          @click="exportOrders"
          class="btn btn-primary btn-xs"
          >Wyeksportuj</a
        >
        <a
          v-if="authStore.canAccess(ACCESS_KEY_ORDERS_TABLE_REQUEST_REPORT_BUTTON)"
          @click="onRequestReportButtonClicked"
          class="btn btn-primary btn-xs"
          >Generuj raport</a
        >
      </div>
    </div>
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="props.showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <slot v-if="props.globalFilterPanel" name="globalFilterPanel"></slot>

      <OrdersTableFilterAndSearch
        v-else
        ref="ordersTableFilterAndSearchChildComponent"
        :longerSearchBar="props.longerSearchBar"
        :userFilterEnabled="props.userFilterEnabled"
        :hideWorkerFilter="props.hideWorkerFilter"
        @fetchData="onFetchDataWithFiltersRequest"
      />

      <TableElementsInfo :metaObject="ordersObject ? ordersObject.meta : null" />

      <div v-if="isNotEmptyArray(ordersObject?.data)" class="table-responsive">
        <table class="table table-striped first-column-fixed-table">
          <thead>
            <tr>
              <th
                v-for="column in visibleColumns"
                :key="column.key"
                draggable="true"
                @dragstart="handleDragStart(column)"
                @dragover="handleDragOver($event, column)"
                @drop="handleColumnDrop"
                :class="{
                  'drag-over': dragOverColumn === column,
                  'being-dragged': draggedColumn === column
                }"
                :style="[getColumnThStyle(column), { cursor: 'move' }]"
                @click="column.sortable ? sortBy(column.sortKey) : undefined"
              >
                {{ column.header }}
                <span v-if="column.sortable" v-html="getSortingIcon(currentSort, column.sortKey)" />
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(order, i) in ordersObject.data"
              :key="i"
              @click="selectableItems ? pickItem(order) : showDetails(order)"
              :class="{ 'picked-row': order.picked }"
              style="cursor: pointer"
            >
              <template v-for="column in visibleColumns" :key="column.key">
                <td :style="getColumnTdStyle(column)">
                  <!-- Direction column -->
                  <template v-if="column.key === 'direction'">
                    <span
                      class="label label-primary"
                      :style="
                        order?.direction?.color != null
                          ? 'background-color:' + order.direction.color
                          : ''
                      "
                    >
                      {{ order?.direction?.name ?? '-' }}</span
                    >
                  </template>

                  <!-- Range column -->
                  <template v-else-if="column.key === 'order_range_complete'">
                    {{
                      order?.order_range_complete != null && order?.order_range_complete !== ''
                        ? order?.order_range_complete
                        : '-'
                    }}
                  </template>

                  <!-- Claim column -->
                  <template v-else-if="column.key === 'claim'">
                    {{ order?.claim ? 'Tak' : '-' }}
                  </template>

                  <!-- LN column -->
                  <template v-else-if="column.key === 'ln_present'">
                    {{ order?.ln_present ? 'Tak' : '-' }}
                  </template>

                  <!-- Addresses column -->
                  <template v-else-if="column.key === 'addresses'">
                    {{
                      isNotEmptyArray(order?.addresses) && order.addresses[0].address
                        ? trimString(order.addresses[0].address)
                        : '-'
                    }}
                  </template>

                  <!-- Coordinator column -->
                  <template v-else-if="column.key === 'coordinator_opl'">
                    <span
                      v-if="
                        order?.coordinator_opl?.firstName == null ||
                        order?.coordinator_opl?.lastName == null
                      "
                    >
                      -
                    </span>
                    <span v-else>
                      {{ order.coordinator_opl.firstName + ' ' + order.coordinator_opl.lastName }}
                    </span>
                  </template>

                  <!-- Receive date column -->
                  <template v-else-if="column.key === 'receive_date'">
                    {{ order?.receive_date != null ? getDateWithoutTime(order.receive_date) : '-' }}
                  </template>

                  <!-- Deadline column -->
                  <template v-else-if="column.key === 'deadline'">
                    {{ order?.deadline != null ? getDateWithoutTime(order.deadline) : '-' }}
                  </template>

                  <!-- Order status column -->
                  <template v-else-if="column.key === 'order_status'">
                    <span
                      class="label label-primary"
                      :style="
                        order?.order_status?.color != null
                          ? 'background-color:' + order.order_status.color
                          : ''
                      "
                    >
                      {{ order?.order_status?.name ?? '-' }}</span
                    >
                  </template>

                  <!-- Disassembly status column -->
                  <template v-else-if="column.key === 'disassembly_status'">
                    <span
                      class="label label-primary"
                      :style="
                        order?.disassembly_status?.color != null
                          ? 'background-color:' + order.disassembly_status.color
                          : ''
                      "
                    >
                      {{ order?.disassembly_status?.name ?? '-' }}</span
                    >
                  </template>

                  <!-- Disassembly status column -->
                  <template v-else-if="column.key === 'disassembly_start_date'">
                    {{ order?.start?.date != null ? getDateWithoutTime(order.start.date) : '-' }}
                  </template>

                  <!-- Disassembly status column -->
                  <template v-else-if="column.key === 'disassembly_end_date'">
                    {{ order?.stop?.date != null ? getDateWithoutTime(order.stop.date) : '-' }}
                  </template>

                  <!-- Created by column -->
                  <template v-else-if="column.key === 'created_by'">
                    {{ order.created_by.firstName + ' ' + order.created_by.lastName }}
                  </template>

                  <!-- Default column rendering -->
                  <template v-else>
                    {{
                      column.key.includes('.')
                        ? getNestedValue(order, column.key) ?? '-'
                        : order[column.key] ?? '-'
                    }}
                  </template>
                </td>
              </template>

              <td style="white-space: nowrap">
                <div class="float-right">
                  <button
                    v-if="!props.selectableItems"
                    class="btn btn-outline btn-primary btn-xs mr-2"
                    type="button"
                    @click="showDetails(order)"
                    onclick="event.stopPropagation()"
                  >
                    Pokaż
                  </button>

                  <button
                    v-if="props.selectableItems && !props.multiSelections"
                    class="btn btn-outline btn-primary btn-xs mr-2"
                    type="button"
                    @click="pickItem(order)"
                    onclick="event.stopPropagation()"
                  >
                    Wybierz
                  </button>

                  <button
                    v-if="props.selectableItems && props.multiSelections"
                    class="btn btn-primary btn-xs mr-2"
                    :class="order.picked ? 'btn-danger' : ''"
                    type="button"
                    @click="pickItem(order)"
                    onclick="event.stopPropagation()"
                  >
                    {{ order.picked ? 'Odznacz' : 'Zaznacz' }}
                  </button>

                  <button
                    v-if="props.editButton"
                    class="btn btn-outline btn-primary btn-xs"
                    type="button"
                    @click="edit(order)"
                    onclick="event.stopPropagation()"
                  >
                    Edytuj
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="ordersObject?.error">
        <ServerErrorPanel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>

      <div v-if="isNotEmptyArray(ordersObject?.data)" class="row mt-3">
        <div class="col-xl-3">
          <ColumnsVisibilityDropdown
            :columns="accessibleColumns"
            :isSettingsSaving="isSettingsSaving"
            @save="saveColumnsSettings"
            @showAll="handleColumnVisibilityChange('all')"
            @change="handleColumnVisibilityChange"
            @resetOrder="resetColumnsOrder"
          />
        </div>

        <div class="col-xl-6">
          <Bootstrap4Pagination
            v-if="ordersObject?.data"
            align="center"
            :data="ordersObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></Bootstrap4Pagination>
        </div>

        <div class="col-xl-3">
          <PaginationCountPicker
            :paginationKey="MAIN_ORDERS_TABLE_PAGINATION_KEY"
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          ></PaginationCountPicker>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.first-column-fixed-table th:first-child,
td:first-child {
  position: sticky;
  left: 0px;
}

.first-column-fixed-table > tbody > tr:nth-child(odd) > td {
  background-color: rgb(242, 242, 242);
}

.first-column-fixed-table > tbody > tr:nth-child(even) > td,
.first-column-fixed-table > thead > tr:first-child > th {
  background-color: white;
}
</style>
