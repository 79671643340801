export const REGISTER_REPORT_HEADERS = [
  'LP',
  'Status zlecenia',
  'Numer zlecenia',
  'Region OTK',
  'Strefa',
  'Dane szczegółowe zlecenia',
  'Inicjatywa',
  'Zakres',
  'Adres',
  'Koordynator',
  ['Data wpływu do firmy Rado', '', '', 'RRRR-MM-DD'],
  ['Data rejestracji zlecenia', '', '', 'RRRR-MM-DD'],
  'Nr EBP',
  'Ilość',
  ['Termin realizacji demontażu', 'Oczekiwany', 'RRRR-MM-DD'],
  ['', 'Opóźnienie dni:', 'Od dnia', ''],
  'Ilość faktyczna zdemontowanych elementów',
  'Informacja o kablach z LN',
  ['Słupy', 'Miejsce składowania'],
  ['', 'Ilość (sztuk)'],
  ['', 'Status transportowy'],
  'Liczba PO',
  'Numer PO',
  'Typ PO',
  ['Data otrzymania PO', '', 'RRRR-MM-DD'],
  'Ilość (sztuk)',
  'Status geodezji',
  'Zakończony demontaż/aktualizacja',
  'Powody przerwania',
  'Numer',
  ['', '', 'opis wewnętrzny zlecenia'],
  'Zgodny'
];

export const REGISTER_REPORT_COLUMN_WIDTHS = [
  3, 3, 5, 40, 30, 15, 15, 15, 10, 70, 60, 25, 15, 15, 20, 10, 15, 15, 15, 15, 10, 10, 20, 10, 20,
  20, 15, 10, 30, 30, 30, 25, 50, 10
];

export const ONLY_FIRST_ROW_COLUMNS_IDS = [11, 12, 18, 20, 24];
