// converts e.g. "2020-06-22 15:09:07" to "2020-06-22"
export function getDateWithoutTime(datetime) {
  if (datetime == null) return null;

  datetime = datetime.trim();

  return datetime.substring(0, datetime.indexOf(' '));
}

// converts e.g. "2020-06-22 15:09:07" to "15:09:07"
export function getTimeWithoutDate(datetime) {
  if (datetime == null) return null;

  datetime = datetime.trim();

  return datetime.substring(datetime.indexOf(' ') + 1, datetime.length);
}

// converts e.g. "2020-06-24T14:03:12.000000Z" to "2020-06-24 14:03:12"
export function getLocalFormattedDateAndTime(date) {
  if (date == null) return date;

  date = date.trim();

  var formattedDate = date.substring(0, date.indexOf('T'));
  var dateObj = new Date(date);

  return formattedDate + ' ' + dateObj.toLocaleTimeString();
}

export function isFormattedYYYYMM(str) {
  const regex = /^\d{4}-\d{2}$/;
  return regex.test(str);
}

// converts e.g. "2020-06-24" to "2020-06"
export function transformDateToYYYYMM(dateStr) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!regex.test(dateStr)) {
    throw new Error(`Invalid date format. Expected 'yyyy-mm-dd', got ${dateStr}.`);
  }

  const [year, month] = dateStr.split('-');

  return `${year}-${month}`;
}

// adds days to the given date
export function addDaysToDate(dateString, days) {
  const date = new Date(dateString);
  date.setDate(date.getDate() + days);

  return date.toISOString().split('T')[0];
}

// converts e.g. "2020-06-24" to "24.06.2020"
export function transformDateToDDMMYYYY(dateStr) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!regex.test(dateStr)) {
    throw new Error(`Invalid date format. Expected 'yyyy-mm-dd', got ${dateStr}.`);
  }

  const [year, month, day] = dateStr.split('-');

  return `${day}.${month}.${year}`;
}
