import { ref, computed, watch } from 'vue';
import { isNotEmptyArray } from '../helpers/utilsHelper';
import { updateMarkersIcon, updateMarkersPopup } from '../helpers/markersCreator';

export function useMapLogic() {
  const clusterMap = ref();
  const ctrlPressed = ref(false);

  const selectedMarkerIds = computed(() => {
    if (!isNotEmptyArray(clusterMap.value?.markersRef)) return [];

    return clusterMap.value.markersRef
      .filter((marker) => marker.data.selected)
      .map((marker) => marker.markerId);
  });

  watch(selectedMarkerIds, () => {
    clusterMap.value.regroupSelectedMarkers();
  });

  const selectedMarkers = computed(() => {
    if (!isNotEmptyArray(clusterMap.value?.markersRef)) return [];

    return clusterMap.value.markersRef.filter((marker) => marker.data.selected);
  });

  const allSelected = computed(() => {
    if (!isNotEmptyArray(clusterMap.value?.markersRef)) return true;

    return clusterMap.value.markersRef.every((marker) => marker.data.selected);
  });

  const noneSelected = computed(() => {
    if (!isNotEmptyArray(clusterMap.value?.markersRef)) return true;

    return !clusterMap.value.markersRef.some((marker) => marker.data.selected);
  });

  function onSelectedMarkersChanged(markersToSelect) {
    if (!isNotEmptyArray(clusterMap.value?.markersRef)) return;

    const markerIdsToSelect = new Set(markersToSelect.map((marker) => marker.markerId));

    clusterMap.value.markersRef.forEach((marker) => {
      const shouldBeSelected =
        marker.data.selectable !== false &&
        marker.markerId != null &&
        markerIdsToSelect.has(marker.markerId);

      if (marker.data.selected !== shouldBeSelected) {
        updateMarkerSelectedState(marker, shouldBeSelected);
      }
    });
  }

  function onSingleMarkerSelected(markerToSelect) {
    if (markerToSelect.markerId == null) return;
    if (!isNotEmptyArray(clusterMap.value?.markersRef)) return;

    if (ctrlPressed.value) {
      const targetMarker = clusterMap.value.markersRef.find(
        (marker) => marker.markerId === markerToSelect.markerId
      );

      if (targetMarker) {
        const newSelectedValue =
          !targetMarker.data.selected && targetMarker.data.selectable !== false;
        updateMarkerSelectedState(targetMarker, newSelectedValue);
      }
    } else {
      clusterMap.value.markersRef.forEach((marker) => {
        const newSelectedValue =
          marker.data.selectable !== false && markerToSelect.markerId === marker.markerId;
        updateMarkerSelectedState(marker, newSelectedValue);
      });
    }
  }

  function resetSelectedMarkers() {
    if (!isNotEmptyArray(clusterMap.value?.markersRef)) return;

    clusterMap.value.markersRef
      .filter((marker) => marker.data.selected)
      .forEach((marker) => updateMarkerSelectedState(marker, false));
  }

  function selectAllSelectableMarkers() {
    if (!isNotEmptyArray(clusterMap.value?.markersRef)) return;

    clusterMap.value.markersRef
      .filter(
        (marker) =>
          marker.data.selectable !== false && marker.markerId != null && !marker.data.selected
      )
      .forEach((marker) => updateMarkerSelectedState(marker, true));
  }

  function resetSelectedIfCtrl() {
    if (ctrlPressed.value) return;

    resetSelectedMarkers();
  }

  function updateMarkerSelectedState(marker, newSelectedValue) {
    const previousSelected = marker.data.selected;

    marker.data.selected = newSelectedValue;

    if (marker.data.selected !== previousSelected) {
      updateMarker(marker);
    }
  }

  function updateMarker(marker) {
    updateMarkersIcon(marker);
  }

  function focusOnMarker(marker) {
    clusterMap.value.focusOnMarker(marker);
  }

  function updateTooltip(marker) {
    updateMarkersPopup(marker);
  }

  const handleKeyDown = (event) => {
    if (event.code === 'ControlLeft' || event.code === 'ControlRight' || event.key === 'Control')
      ctrlPressed.value = true;
  };

  const handleKeyUp = (event) => {
    if (event.code === 'ControlLeft' || event.code === 'ControlRight' || event.key === 'Control')
      ctrlPressed.value = false;
  };

  const handleWindowBlur = () => {
    ctrlPressed.value = false;
  };

  function cleanup() {
    window.removeEventListener('keydown', handleKeyDown);
    window.removeEventListener('keyup', handleKeyUp);
    window.removeEventListener('blur', handleWindowBlur);
  }

  // created
  window.addEventListener('keydown', handleKeyDown);
  window.addEventListener('keyup', handleKeyUp);
  window.addEventListener('blur', handleWindowBlur);

  return {
    clusterMap,
    allSelected,
    noneSelected,
    selectedMarkerIds,
    onSelectedMarkersChanged,
    onSingleMarkerSelected,
    resetSelectedMarkers,
    selectAllSelectableMarkers,
    resetSelectedIfCtrl,
    updateMarkerSelectedState,
    updateMarker,
    focusOnMarker,
    selectedMarkers,
    updateTooltip,
    cleanup
  };
}
