import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { getMaxArrayLength, isNotEmptyArray } from './utilsHelper';
import { createToaster } from '@meforma/vue-toaster';
import {
  ONLY_FIRST_ROW_COLUMNS_IDS,
  REGISTER_REPORT_COLUMN_WIDTHS,
  REGISTER_REPORT_HEADERS
} from '../data/constants/registerReportHeaders';

const HEADERS_ROW_COLOR = '00b050';
const FILTERS_ROW_COLOR = '9999ff';
const LP_COLUMN_COLOR = 'ffe598';
const LIGHT_BLUE_COLOR = 'ccecff';
const LIGHT_ORANGE_COLOR = 'fef2cb';
const LIGHT_GREEN_COLOR = 'bfd3a5';
const LIGHTER_BLUE_COLOR = 'deeaf6';
const ANOTHER_BLUE_COLOR = 'bdd6ee';
const GRAY_COLOR = 'c0cece';
const DARKER_GRAY_COLOR = 'a5a5a5';
const LIGHT_KHAKI_COLOR = 'e2efd9';
const PINK_COLOR = 'f67ac4';
const GRAY_COLOR_FOR_TEXT = '808080';
const NON_VALID_ORDER_COLOR = 'd96868';
const WRAP_DATA_ROWS = true;

export function generateReport(reportData, worksheetName = 'Worksheet') {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(worksheetName);
  const headers = REGISTER_REPORT_HEADERS;
  const firstDataRowNumber = 7;
  const firstDataColumnNumber = 3;

  const rows = [];

  const maxVerticalCellsInHeaders = getMaxArrayLength(headers);

  for (let i = 0; i < maxVerticalCellsInHeaders; i++) {
    rows.push([]);
  }

  if (!isNotEmptyArray(reportData?.orders)) {
    const toaster = createToaster({ position: 'top-right' });
    toaster.show('Brak danych, nie można wygenerować raportu.', {
      duration: 3000,
      type: 'warning'
    });

    return;
  }

  // Fill with headers
  for (let i = 0; i < headers.length; i++) {
    if (!Array.isArray(headers[i])) {
      rows[0][i] = headers[i];
      continue;
    }

    for (let j = 0; j < headers[i].length; j++) {
      rows[j][i] = headers[i][j];
    }
  }

  for (let i = 0; i < rows.length; i++) {
    rows[i] = ['', ''].concat(rows[i]);
  }

  worksheet.addRow('');
  worksheet.addRows(rows);

  // Fill with the filters
  const filtersRowData = ['', '', ''];

  for (let i = 1; i < headers.length; i++) {
    filtersRowData.push(i);
  }

  worksheet.addRow(filtersRowData);

  // Fill with the data
  const dataRows = [];
  const boldCells = [];
  const grayFontCells = [];
  const nonValidOrdersRows = [];

  for (let i = 0; i < reportData.orders.length; i++) {
    let orderData = reportData.orders[i].columns;
    const count = reportData.orders[i].count;

    for (let j = 0; j < count; j++) {
      const row = ['', '', j === 0 ? i + 1 : ''];
      const isFirstOrderRow = j === 0;

      if (isFirstOrderRow) {
        boldCells.push([dataRows.length, 0]); // for 'Lp.' column
      }

      for (let k = 0; k < orderData.length; k++) {
        const orderColumnData = orderData[k];
        if (Array.isArray(orderColumnData)) {
          row.push(orderColumnData[j] ?? '');
        } else if (isFirstOrderRow) {
          row.push(orderColumnData);
          boldCells.push([dataRows.length, k + 1]);
        } else {
          if (ONLY_FIRST_ROW_COLUMNS_IDS.includes(k)) {
            row.push('');
          } else {
            row.push(orderColumnData);
            grayFontCells.push([dataRows.length, k + 1]);
          }
        }
      }

      // 'Zgodny' column -> change from 1/0 to 'Tak'/'Nie'
      row[33] = row[33] == 1 ? 'Tak' : row[33] == 0 ? 'Nie' : '';

      dataRows.push(row);

      if (reportData.orders[i].is_valid === 0) {
        nonValidOrdersRows.push(dataRows.length);
      }
    }
  }

  worksheet.addRows(dataRows);
  worksheet.getCell('R5').value = reportData.date_now;

  // Bold cells
  boldCells.forEach((boldCell) => {
    worksheet.getCell(firstDataRowNumber + boldCell[0], firstDataColumnNumber + boldCell[1]).font =
      { bold: true };
  });

  // Gray font cells
  grayFontCells.forEach((grayFontCell) => {
    worksheet.getCell(
      firstDataRowNumber + grayFontCell[0],
      firstDataColumnNumber + grayFontCell[1]
    ).font = { color: { argb: GRAY_COLOR_FOR_TEXT } };
  });

  worksheet.mergeCells('C2:C5');
  worksheet.mergeCells('D2:D5');
  worksheet.mergeCells('E2:E5');
  worksheet.mergeCells('F2:F5');
  worksheet.mergeCells('G2:G5');
  worksheet.mergeCells('H2:H5');
  worksheet.mergeCells('I2:I5');
  worksheet.mergeCells('J2:J5');
  worksheet.mergeCells('K2:K5');
  worksheet.mergeCells('L2:L5');
  worksheet.mergeCells('M2:M4');
  worksheet.mergeCells('N2:N4');
  worksheet.mergeCells('O2:O5');
  worksheet.mergeCells('P2:P5');
  worksheet.mergeCells('Q2:R2');
  worksheet.mergeCells('Q4:Q5');
  worksheet.mergeCells('S2:S5');
  worksheet.mergeCells('T2:T5');
  worksheet.mergeCells('U2:W2');
  worksheet.mergeCells('U3:U5');
  worksheet.mergeCells('V3:V5');
  worksheet.mergeCells('W3:W5');
  worksheet.mergeCells('X2:X5');
  worksheet.mergeCells('Y2:Y5');
  worksheet.mergeCells('Z2:Z5');
  worksheet.mergeCells('AA2:AA3');
  worksheet.mergeCells('AA4:AA5');
  worksheet.mergeCells('AB2:AB5');
  worksheet.mergeCells('AC2:AC5');
  worksheet.mergeCells('AD2:AD5');
  worksheet.mergeCells('AE2:AE5');
  worksheet.mergeCells('AF2:AF5');
  worksheet.mergeCells('AG2:AG3');
  worksheet.mergeCells('AG4:AG5');
  worksheet.mergeCells('AH2:AH5');

  // Format cells
  for (let i = 2; i <= 6; i++) {
    worksheet.getRow(i).font = { name: 'Calibri', size: 11, bold: true };
    worksheet.getRow(i).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
  }

  if (WRAP_DATA_ROWS) {
    for (let i = 0; i < reportData.orders.length; i++) {
      worksheet.getRow(i + firstDataRowNumber).alignment = { wrapText: true };
    }
  }

  // Widths
  REGISTER_REPORT_COLUMN_WIDTHS.forEach((element, index) => {
    worksheet.getColumn(index + 1).width = element;
  });

  // Colors
  const mergedHeadersRows = worksheet.getRows(2, 4);
  const filtersRow = worksheet.getRow(6);

  for (let i = 3; i < headers.length + 2; i++) {
    mergedHeadersRows.forEach((mergedRow) => {
      mergedRow.getCell(i + 1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: HEADERS_ROW_COLOR }
      };
    });

    filtersRow.getCell(i + 1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: FILTERS_ROW_COLOR }
    };
  }

  // LP column color
  for (let i = 2; i <= worksheet.rowCount; i++) {
    worksheet.getCell(i, 3).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: LP_COLUMN_COLOR }
    };
  }

  // second color columns
  const secondColorFirstColumnNumber = 4;
  const secondColorLastColumnNumber = 12;

  for (let y = firstDataRowNumber; y <= worksheet.rowCount; y++) {
    for (let x = secondColorFirstColumnNumber; x <= secondColorLastColumnNumber; x++) {
      worksheet.getCell(y, x).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: LIGHT_BLUE_COLOR }
      };
    }
  }

  // third color columns
  const thirdColorFirstColumnNumber = 13;
  const thirdColorLastColumnNumber = 15;

  for (let y = firstDataRowNumber; y <= worksheet.rowCount; y++) {
    for (let x = thirdColorFirstColumnNumber; x <= thirdColorLastColumnNumber; x++) {
      worksheet.getCell(y, x).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: LIGHT_ORANGE_COLOR }
      };
    }
  }

  // fourth color columns
  const fourthColorFirstColumnNumber = 16;
  const fourthColorLastColumnNumber = 16;

  for (let y = firstDataRowNumber; y <= worksheet.rowCount; y++) {
    for (let x = fourthColorFirstColumnNumber; x <= fourthColorLastColumnNumber; x++) {
      worksheet.getCell(y, x).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: LIGHT_GREEN_COLOR }
      };
    }
  }

  // fifth color columns
  const fifthColorFirstColumnNumber = 17;
  const fifthColorLastColumnNumber = 18;

  for (let y = firstDataRowNumber; y <= worksheet.rowCount; y++) {
    for (let x = fifthColorFirstColumnNumber; x <= fifthColorLastColumnNumber; x++) {
      worksheet.getCell(y, x).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: LIGHT_ORANGE_COLOR }
      };
    }
  }

  // sixth color columns
  const sixthColorFirstColumnNumber = 19;
  const sixthColorLastColumnNumber = 20;

  for (let y = firstDataRowNumber; y <= worksheet.rowCount; y++) {
    for (let x = sixthColorFirstColumnNumber; x <= sixthColorLastColumnNumber; x++) {
      worksheet.getCell(y, x).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: LIGHTER_BLUE_COLOR }
      };
    }
  }

  // seventh color columns
  const seventhColorFirstColumnNumber = 21;
  const seventhColorLastColumnNumber = 23;

  for (let y = firstDataRowNumber; y <= worksheet.rowCount; y++) {
    for (let x = seventhColorFirstColumnNumber; x <= seventhColorLastColumnNumber; x++) {
      worksheet.getCell(y, x).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: ANOTHER_BLUE_COLOR }
      };
    }
  }

  // eight color columns
  const eightColorFirstColumnNumber = 24;
  const eightColorLastColumnNumber = 27;

  for (let y = firstDataRowNumber; y <= worksheet.rowCount; y++) {
    for (let x = eightColorFirstColumnNumber; x <= eightColorLastColumnNumber; x++) {
      worksheet.getCell(y, x).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: GRAY_COLOR }
      };
    }
  }

  // ninth color columns
  const ninthColorFirstColumnNumber = 28;
  const ninthColorLastColumnNumber = 28;

  for (let y = firstDataRowNumber; y <= worksheet.rowCount; y++) {
    for (let x = ninthColorFirstColumnNumber; x <= ninthColorLastColumnNumber; x++) {
      worksheet.getCell(y, x).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: DARKER_GRAY_COLOR }
      };
    }
  }

  // tenth color columns
  const tenthColorFirstColumnNumber = 29;
  const tenthColorLastColumnNumber = 31;

  for (let y = firstDataRowNumber; y <= worksheet.rowCount; y++) {
    for (let x = tenthColorFirstColumnNumber; x <= tenthColorLastColumnNumber; x++) {
      worksheet.getCell(y, x).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: LIGHT_KHAKI_COLOR }
      };
    }
  }

  // eleventh color columns
  const eleventhColorFirstColumnNumber = 32;
  const eleventhColorLastColumnNumber = 32;

  for (let y = firstDataRowNumber; y <= worksheet.rowCount; y++) {
    for (let x = eleventhColorFirstColumnNumber; x <= eleventhColorLastColumnNumber; x++) {
      worksheet.getCell(y, x).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: PINK_COLOR }
      };
    }
  }

  for (let i = 0; i < worksheet.columnCount; i++) {
    if (i < 2) continue;

    for (let row = 2; row <= worksheet.rowCount; row++) {
      worksheet.getCell(row, i + 1).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    }
  }

  // Non valid order color
  nonValidOrdersRows.forEach((rowNumber) => {
    for (let i = firstDataColumnNumber; i <= worksheet.columnCount; i++) {
      worksheet.getCell(rowNumber + firstDataRowNumber - 1, i).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: NON_VALID_ORDER_COLOR }
      };
    }
  });

  worksheet.views = [{ state: 'frozen', xSplit: 5, ySplit: maxVerticalCellsInHeaders + 2 }];
  worksheet.autoFilter = {
    from: { row: maxVerticalCellsInHeaders + 2, column: 3 },
    to: { row: 10000, column: headers.length + 2 }
  };

  // Export xlsx
  workbook.xlsx
    .writeBuffer()
    .then((buffer) =>
      saveAs(
        new Blob([buffer]),
        `Raport z rejestru zleceń - ${new Date().toLocaleDateString()}.xlsx`
      )
    )
    .catch((err) => console.log('generateReport(): Error writing excel export.', err));
}
