import { defineStore } from 'pinia';
import { ref } from 'vue';

export const AVAILABLE_MODES = {
  ZEN: 'zen'
};

export const MODE_CONFIGS = {
  [AVAILABLE_MODES.ZEN]: {
    id: AVAILABLE_MODES.ZEN,
    name: 'Zen Mode',
    shortcut: {
      ctrl: true,
      shift: true,
      alt: false,
      key: 'z'
    },
    escapeEnabled: true
  }
};

export const useModesStore = defineStore('modes', () => {
  const activeMode = ref(null);

  function setMode(mode) {
    activeMode.value = mode;
  }

  function toggleMode(mode) {
    activeMode.value = activeMode.value === mode ? null : mode;
  }

  function isActive(mode) {
    return activeMode.value === mode;
  }

  return {
    activeMode,
    setMode,
    toggleMode,
    isActive
  };
});
